import { CardType } from '../../enums/paymentForm';
import { IGetPaymentFrequency } from '../../store/features/paymentFrequency/types';

export interface PaymentAmountButton {
	title: string;
	value: number;
}

export interface PaymentFrequencyButton {
	title: string;
	value: number;
}

const generateButton = (amount: number, isTotal: boolean = false): PaymentAmountButton => ({
	title: isTotal ? 'Total' : amount.toString(),
	value: amount,
});

export const generatePaymentButtons = (outstandingAmount: number = 0): PaymentAmountButton[] => {
	if (outstandingAmount <= 100) {
		return [generateButton(outstandingAmount, true)];
	}
	if (outstandingAmount < 500) {
		return [generateButton(20), generateButton(100), generateButton(outstandingAmount, true)];
	}
	if (outstandingAmount <= 2000) {
		return [generateButton(50), generateButton(100), generateButton(outstandingAmount, true)];
	}
	if (outstandingAmount <= 10000) {
		return [generateButton(100), generateButton(200), generateButton(outstandingAmount, true)];
	}
	return [generateButton(100), generateButton(1000), generateButton(outstandingAmount, true)];
};

export const calculatePercent = (paymentAmount: string, outstandingAmount: number) => (parseFloat(paymentAmount) / outstandingAmount) * 100;

export const generatePaymentFrequencyButtons = (paymentFrequencies: IGetPaymentFrequency[]): PaymentFrequencyButton[] =>
	// Order by weekly, fortnightly - Hide Monthly, One Off
	paymentFrequencies
		.filter((frequency) => frequency.IntervalDays > 1)
		.slice()
		.sort((a, b) => (a.PeriodsPerAnnum > b.PeriodsPerAnnum ? -1 : 1))
		.map((frequency) => ({
			title: frequency.Name,
			value: frequency.PeriodicFrequencyID,
		}));

export const getCardType = (cardNumber: string): CardType | undefined => {
	const visaRegEx = {
		VISAELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
		VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
	};
	if (Object.values(visaRegEx).some((x) => x.test(cardNumber))) return CardType.VISA;

	const masterRegEx = /^5[1-5][0-9]{14}$/;
	if (masterRegEx.test(cardNumber)) return CardType.MASTER;

	return undefined;
};

export const calculateMerchantFeeStrings = (paymentAmount: string, merchantFeePercent: number | undefined): { totalFees: string; total: string } => {
	let totalFees = '0';
	if (!merchantFeePercent || isNaN(merchantFeePercent) || merchantFeePercent <= 0 || isNaN(parseFloat(paymentAmount))) {
		return { totalFees, total: isNaN(parseFloat(paymentAmount)) ? '0' : paymentAmount };
	}
	totalFees = ((parseFloat(paymentAmount) * merchantFeePercent) / 100).toFixed(2).replace(/[.]00$/, '');
	return { totalFees, total: (parseFloat(totalFees) + parseFloat(paymentAmount)).toFixed(2).replace(/[.]00$/, '') };
};

import React from 'react';

export const BubbleEllipsis = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M17.006 11.378a1.25 1.25 0 1 1 0-2.501 1.25 1.25 0 0 1 0 2.501m-5.002 0a1.251 1.251 0 1 1 0-2.502 1.251 1.251 0 0 1 0 2.502m-5.002 0a1.251 1.251 0 1 1 0-2.502 1.251 1.251 0 0 1 0 2.502M13.879 2h-3.751a8.022 8.022 0 0 0-5.742 2.382A8.03 8.03 0 0 0 2 10.128c0 4.271 3.312 7.784 7.503 8.104v3.15a.625.625 0 0 0 1.085.422l3.254-3.549h.037a8.023 8.023 0 0 0 5.742-2.38 8.032 8.032 0 0 0 2.386-5.747C22.007 5.646 18.36 2 13.879 2"
			fillRule="evenodd"
		/>
	</svg>
);

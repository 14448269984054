import clsx from 'clsx';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import { ButtonOversize } from '../../../ButtonOversize/buttonOversize';
import { BpayHorizontal, CardPayment, DirectDebitPayment } from '../../../PantheraIcon';
import useStyles from './paymentMethods.styles';
import { AvailablePaymentMethod } from '../../../../enums/paymentForm';
import { IGetPaymentMethods } from '../../../../store/features/paymentMethod/types';
import { RootState } from '../../../../store/rootReducer';
import { PaymentMethodsLoader } from './paymentMethodsLoader';

interface PaymentMethodButtons {
	iconLeft: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => JSX.Element;
	title: string;
	id: AvailablePaymentMethod;
	dataid: string;
	path: string | undefined;
	arrangementOrder: number;
	oneOffOrder: number;
}

const paymentMethodButtons: PaymentMethodButtons[] = [
	{
		iconLeft: CardPayment,
		title: 'Card payment',
		id: AvailablePaymentMethod.CARD,
		dataid: 'btnCard',
		path: undefined,
		arrangementOrder: 2,
		oneOffOrder: 1,
	},
	{
		iconLeft: DirectDebitPayment,
		title: 'Direct debit',
		id: AvailablePaymentMethod.DIRECT,
		dataid: 'btnDirect',
		path: undefined,
		arrangementOrder: 1,
		oneOffOrder: 2,
	},
	{
		iconLeft: BpayHorizontal,
		title: 'BPAY',
		id: AvailablePaymentMethod.BPAY,
		dataid: 'btnBpay',
		path: undefined,
		arrangementOrder: 3,
		oneOffOrder: 3,
	},
];

type PaymentMethodsProps = {
	isArrangement?: boolean;
	selected?: IGetPaymentMethods;
	onSelect?: (selectedPaymentMethodID: AvailablePaymentMethod) => void;
	onRemove?: () => void;
};

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({ isArrangement = false, selected, onSelect, onRemove }) => {
	const styles = useStyles();
	const paymentMethodButtonsContent = isArrangement
		? paymentMethodButtons.sort((a, b) => a.arrangementOrder - b.arrangementOrder)
		: paymentMethodButtons.sort((a, b) => a.oneOffOrder - b.oneOffOrder);

	const { merchantFeePercent, methods, loading } = useSelector(
		(state: RootState) => ({
			merchantFeePercent: state.customer.customerDetails?.merchantFeePercent,
			methods: state.paymentMethod.methods,
			loading: state.customer.loading,
		}),
		shallowEqual,
	);

	const availablePaymentMethodButtons = paymentMethodButtonsContent.filter((button) => methods.some((method) => method.Name === button.id));

	return (
		<div className={styles.root}>
			{loading ? (
				<PaymentMethodsLoader />
			) : (
				<>
					{availablePaymentMethodButtons
						.filter((button) => (selected && button.id === selected.Name) || !selected)
						.map((button) => (
							<ButtonOversize
								key={button.id}
								data-cy={button.dataid}
								className={clsx(styles.button, button.id === selected?.Name && styles.selectedButton)}
								LinkProps={button.path ? { to: button.path } : undefined}
								iconLeft={<button.iconLeft className={styles.iconLeft} />}
								selected={button.id === selected?.Name}
								solo={!!selected}
								onClick={(e) => {
									onSelect ? onSelect(button.id) : e.preventDefault();
								}}
								onRemove={onRemove}
							>
								{button.title}
							</ButtonOversize>
						))}
					{selected && selected.Name === AvailablePaymentMethod.CARD && !!merchantFeePercent && (
						<Alert className={styles.merchantFee} severity="info">
							A surcharge of {merchantFeePercent}% will be added to the payment amount for all Visa or MasterCard.
						</Alert>
					)}
				</>
			)}
		</div>
	);
};

/* eslint-disable max-len */
import { Box, Button, Container, Grid, Paper, Typography, Link } from '@material-ui/core';
import React from 'react';
import { ExternalLink } from '../../../../elements/PantheraIcon/ExternalLink';
import { Link as ReactLink } from 'react-router-dom';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { WebRoutes, ArfRoute } from '../../../../enums/routerPath';
import { ARFCustomerFooter } from '../../elements/Footer/footer';
import { ARFCustomerHeader } from '../../elements/Header/header';
import useStyles from './home.styles';
import { ARFFAQ } from './FAQ/faq';
import { institutions, helpImages } from './data';
import debtHelplineBanner from '../../../../static/images/marketing/debt-helpline.png';

export const CustomerARFHome: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<ARFCustomerHeader />
			<Container className={styles.row} maxWidth="xl">
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
					<Grid item xs>
						<Typography className={styles.heroTitle} variant="h1" gutterBottom align="center">
							<span style={{ display: 'block' }}>
								<Typography component="span" variant="inherit" color="secondary">
									Australian Receivables Finance (ARF)
								</Typography>{' '}
							</span>
							is the purchase debt arm of{' '}
							<Typography component="span" variant="inherit" color="secondary" display="block" align="center">
								ARL Collect
							</Typography>
						</Typography>
						<Typography variant="body1" align="center">
							Login now to start taking control of your finances though our self managed portal.
						</Typography>
						<div className={styles.buttonContainer}>
							<Button
								className={styles.button}
								color="secondary"
								variant="contained"
								component={ReactLink}
								to={WebRoutes.CUSTOMERS.path}
							>
								Log in
							</Button>
						</div>
					</Grid>
				</Grid>
			</Container>
			<div className={styles.concave}>
				<Container>
					<Grid className={styles.concaveContainer} container>
						<Box id="who-we-work-with" alignItems="center" display="flex" flexDirection="column" justifyContent="center">
							<Typography align="center" variant="h1">
								We work with Australia‘s most trusted institutions.
							</Typography>

							<Paper className={styles.institutionsGrid} elevation={3}>
								<Grid container>
									{institutions.map(({ icon, text }, i) => (
										<Grid key={i} item md={4} xs={12}>
											{icon}
											<Typography variant="body1">{text}</Typography>
										</Grid>
									))}
								</Grid>
							</Paper>
						</Box>
					</Grid>
				</Container>
			</div>

			<div id="how-we-can-help" className={styles.help}>
				<Container>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
						<Typography variant="h3">How we can help you</Typography>
						<Box mt={7}>
							<Grid alignItems="center" container justifyContent="center" spacing={9}>
								<Grid item>
									<div>
										<img alt="" src={helpImages.manageable} />
										<Typography variant="h5">Manageable</Typography>
										<Typography variant="body1">
											Login 24/7 to our portal and view your accounts, offers and ask questions.
										</Typography>
									</div>
								</Grid>

								<Grid item>
									<div>
										<img alt="" src={helpImages.affordable} />
										<Typography variant="h5">Affordable</Typography>
										<Typography variant="body1">
											We provide a range of payment options to meet your current financial and social position.
										</Typography>
									</div>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Container>
			</div>

			<div id="faqs" className={styles.faq}>
				<Container>
					<Spacing units={3}>
						<Typography variant="h4" align="center">
							FAQs
						</Typography>
						<ARFFAQ />
						<Paper className={styles.helpCentreBanner}>
							<Spacing display="flex" flexDirection="column" units={3}>
								<Typography variant="h5">Need further assistance?</Typography>
								<ul>
									<li>
										<Link href="https://ndh.org.au/covid19/covid-19-changes-government-assistance/">
											Information on government schemes <ExternalLink />
										</Link>{' '}
										you may be eligible for
									</li>
									<li>
										<Link href="https://moneysmart.gov.au/">
											Advice and financial counselling support resources <ExternalLink />
										</Link>
									</li>
									<li>
										<Link component={ReactLink} to={ArfRoute.HARDSHIP.path}>
											Our hardship policy <ExternalLink />
										</Link>{' '}
										and how to lodge a hardship request
									</li>
								</ul>
								<Button color="secondary" variant="contained" href="tel: 1300845267">
									Call Us 1300 845 267
								</Button>
							</Spacing>
							<div />
						</Paper>

						<Paper className={styles.debtHelplineBanner}>
							<Link href="https://ndh.org.au/">
								<img alt="Financial Counselling Australia" src={debtHelplineBanner} />
							</Link>
							<Typography variant="body1">
								If you&apos;re in crisis and struggling to make ends meet, contact the National Debt Helpline <b>1800 007 007</b> —
								open from 9.30am to 4.30pm, Monday to Friday.
							</Typography>
						</Paper>
					</Spacing>
				</Container>
			</div>
			<ARFCustomerFooter />
		</Box>
	);
};

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export const GoogleAnalytics: React.FC = () => {
	const location = useLocation();
	const [initialized, setInitialized] = React.useState(false);

	useEffect(() => {
		if (process.env.NODE_ENV !== 'development') {
			ReactGA.initialize(window.config.REACT_APP_GOOGLE_ANALYTICS);
			setInitialized(true);
		}
	}, []);

	useEffect(() => {
		if (initialized) {
			// Fixes helmet lag https://github.com/nfl/react-helmet/issues/189
			setTimeout(() => {
				ReactGA.pageview(location.pathname + location.search);
			}, 1000);
		}
	}, [initialized, location]);

	return null;
};

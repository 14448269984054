import { Button, Dialog, Grid, IconButton, Link, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { Logo } from '../../../../../../elements/Branding/Logo';
import { Close } from '../../../../../../elements/PantheraIcon/Close';
import { Spacing } from '../../../../../../elements/Spacing/spacing';
import { WrapWithLink } from '../../../../../../elements/WrapWithLink/wrapWithLink';
import { BusinessRoutes, WebRoutes } from '../../../../../../enums/routerPath';
import { spacingUnits } from '../../../../../../theme/theme';
import { ArfRoute } from '../../../../../../enums/routerPath';
import useStyles from './mobileHeader.styles';

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
	return <Slide direction="down" {...props} ref={ref} />;
});

type CustomerMobileHeaderProps = {
	onClose: () => void;
};

export const CustomerMobileHeader: React.FC<CustomerMobileHeaderProps> = ({ onClose }) => {
	const styles = useStyles();
	const [modalOpen, setModalOpen] = React.useState(true);
	const handleCloseClick = () => setModalOpen(false);
	return (
		<Dialog className={styles.root} fullScreen open={modalOpen} onClose={handleCloseClick} TransitionComponent={Transition} onExited={onClose}>
			<Grid className={styles.logoWrapper} container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<WrapWithLink
						className={styles.logo}
						LinkProps={{
							to: WebRoutes.HOME.path,
						}}
					>
						<Logo />
					</WrapWithLink>
				</Grid>
				<Grid item>
					<IconButton edge="end" color="primary" aria-label="menu" onClick={handleCloseClick}>
						<Close style={{ width: 24 }} />
					</IconButton>
				</Grid>
			</Grid>
			<Grid className={styles.menuItemsWrapper} container direction="column" justifyContent="flex-start" alignItems="center">
				<Spacing units={5}>
					<Grid item>
						<Link component={NavLink} to={WebRoutes.HOW_IT_WORKS.path} underline="none" exact>
							How it works
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={WebRoutes.SUPPORT.path} underline="none" exact>
							Help
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={WebRoutes.ABOUT.path} underline="none" exact>
							About Us
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={WebRoutes.CONTACT.path} underline="none" exact>
							Contact
						</Link>
					</Grid>
					<Grid item>
						<Link component={NavLink} to={ArfRoute.HOME.path} underline="none" exact>
							ARF
						</Link>
					</Grid>
				</Spacing>
				<Spacing units={3} style={{ marginTop: spacingUnits(5) }}>
					<Grid item>
						<Button color="primary" size="medium" fullWidth variant="outlined" component={RouterLink} to={BusinessRoutes.HOME.path}>
							Business
						</Button>
					</Grid>
					<Grid item>
						<Button color="secondary" size="medium" fullWidth variant="contained" component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
							Manage my account
						</Button>
					</Grid>
				</Spacing>
			</Grid>
		</Dialog>
	);
};

import React from 'react';

export const HdMedicalFolder = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 80 80" {...props}>
		<path
			d="M25.828 7a4.43 4.43 0 0 1 4.425 4.221l.005.209h26.58c.571 0 1.041.432 1.101.987l.007.12v3.323h3.322c.572 0 1.042.433 1.102.988l.007.12v32.118h9.968a1.11 1.11 0 0 1 1.081 1.343l-.036.128-7.753 22.15a1.072 1.072 0 0 1-.928.742l-.117.003H20.29c-.358 0-.692-.173-.899-.461l-.071-.114-12.182-22.15a1.105 1.105 0 0 1 .848-1.635l.12-.006h8.862V12.538c0-.57.432-1.042.986-1.102l.12-.006h3.324A4.43 4.43 0 0 1 25.828 7zm25.926 44.301H9.98l10.965 19.936h41.774L51.754 51.302zm19.028 0h-8.405V66.02l1.993 3.617 6.412-18.334zM60.16 18.075h-2.215v39.871h-.01l2.225 4.046V18.076zm-32.118-4.43h-8.861v35.44h33.227c.358.001.692.175.899.462l.07.113 2.353 4.278V13.645H30.258v3.323a3.322 3.322 0 0 1-6.64.189l-.005-.189v-1.107h2.215v1.107a1.107 1.107 0 0 0 2.208.121l.007-.12v-3.324zm19.936 29.903v2.215H22.506v-2.215h25.473zM14.753 29.15v15.505h-2.215V29.15h2.215zm37.656 9.968v2.215H22.506v-2.215h29.903zm-25.474-4.43v2.215H24.72v-2.215h2.215zm25.474 0v2.215H29.15v-2.215h23.259zm14.398-1.107v2.215h-2.215v-2.215h2.215zM40.226 16.968c.57 0 1.041.432 1.101.987l.007.12v3.323h3.322c.57 0 1.041.432 1.101.987l.007.12v4.43c0 .571-.432 1.041-.988 1.101l-.12.007h-3.322v3.323c0 .57-.432 1.041-.988 1.101l-.12.007h-4.43c-.57 0-1.04-.433-1.1-.988l-.007-.12v-3.323h-3.323a1.108 1.108 0 0 1-1.102-.987l-.006-.121v-4.43c0-.57.432-1.04.987-1.1l.12-.007h3.324v-3.322c0-.571.432-1.041.986-1.102l.12-.006h4.43zm22.15-5.538a4.431 4.431 0 0 1 4.426 4.221l.005.209v15.506h-2.215V15.86c0-1.17-.908-2.128-2.057-2.21l-.158-.005H60.16V11.43h2.216zm-23.258 7.753h-2.215v3.323c0 .57-.432 1.04-.987 1.1l-.12.007h-3.322v2.215h3.322c.57 0 1.04.432 1.1.986l.007.12v3.324h2.215v-3.323c0-.57.432-1.04.987-1.1l.12-.007h3.324v-2.215h-3.323a1.108 1.108 0 0 1-1.102-.987l-.006-.12v-3.323zM14.753 24.72v2.215h-2.215V24.72h2.215zm38.763-3.322v2.215h-2.215v-2.215h2.215zm-.127-4.43v2.215h-2.277v-2.215h2.277zM25.828 9.216c-1.17 0-2.128.906-2.21 2.055l-.005.159h4.43a2.214 2.214 0 0 0-2.215-2.214z"
			fillRule="evenodd"
		/>
	</svg>
);

import { Accordion, AccordionDetails, AccordionSummary, Box, BoxProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import { MinusLarge } from '../../../../../elements/PantheraIcon/MinusLarge';
import { PlusLarge } from '../../../../../elements/PantheraIcon/PlusLarge';
import { faqs } from '../data';

import useStyles from './faq.styles';

type FAQProps = BoxProps & {
	className?: string;
	isSupport?: boolean;
};

export const ARFFAQ: React.FC<FAQProps> = ({ isSupport = false, ...props }) => {
	const styles = useStyles();

	const [indexOpen, setIndexOpen] = useState<Record<number, boolean>>(isSupport ? {} : { 0: true, 1: true });

	const handleChange = (index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setIndexOpen({ ...indexOpen, [index]: isExpanded });
	};

	return (
		<Box {...props} className={clsx(styles.root, props.className)}>
			{faqs.map(({ content, title }, i) => (
				<Accordion key={title} expanded={!!indexOpen[i]} onChange={handleChange(i)}>
					<AccordionSummary
						expandIcon={
							<>
								<PlusLarge className="iconExpand" />
								<MinusLarge className="iconClose" />
							</>
						}
					>
						<Typography variant="h6">{title}</Typography>
					</AccordionSummary>
					<AccordionDetails>{content}</AccordionDetails>
				</Accordion>
			))}
		</Box>
	);
};

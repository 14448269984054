import React from 'react';

export const InformationSolid = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12A10.5 10.5 0 0 0 12 1.5zm0 3.75a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm3 12.84H9v-1.68h2.16v-5.07H9.75V9.66h3.098v6.75H15v1.68z"
			fillRule="evenodd"
		/>
	</svg>
);

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ai } from './telemetryService';

type TelemetryProviderProps = {
	instrumentationKey: string;
	after: () => void;
};

interface IStateProps {
	initialized: boolean;
}

export class TelemetryProvider extends Component<TelemetryProviderProps & RouteComponentProps, IStateProps> {
	constructor(props: TelemetryProviderProps & RouteComponentProps) {
		super(props);
		this.state = {
			initialized: false,
		};
	}

	componentDidMount() {
		const { history } = this.props;
		const { initialized } = this.state;
		const AppInsightsInstrumentationKey = this.props.instrumentationKey;
		if (!initialized && AppInsightsInstrumentationKey && Boolean(history) && process.env.NODE_ENV !== 'development') {
			ai.initialize(AppInsightsInstrumentationKey, history as object);
			this.setState({ initialized: true });
		}
		this.props.after();
	}

	render() {
		const { children } = this.props;
		return <>{children}</>;
	}
}
export const TelemetryTracking = withRouter(
	process.env.NODE_ENV !== 'development' ? withAITracking(ai.reactPlugin, TelemetryProvider, undefined, 'telemetry') : TelemetryProvider,
);

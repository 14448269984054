import { Box, Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { ARFCustomerHeader } from '../../elements/Header/header';
import useStyles from './hardship.style';
import { ARFCustomerFooter } from '../../elements/Footer/footer';
import { ArfRoute } from '../../../../enums/routerPath';
import { PantheraBreadcrumbs } from '../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { Spacing } from '../../../../elements/Spacing/spacing';

const breadcrumbs = [
	{
		title: 'ARF',
		LinkProps: {
			to: ArfRoute.HOME.path,
		},
	},
	{
		title: 'Hardship',
		LinkProps: {
			to: ArfRoute.HARDSHIP.path,
		},
	},
];

export const HardshipARF: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Box className={styles.root}>
			<ARFCustomerHeader className={styles.greyHeader} />
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
					<Typography variant="h4">Hardship</Typography>
					<Typography variant="body1">
						“Australian Receivables Finance is an organisation that believes in treating all people with dignity and respect. This policy
						has been developed to assist customers in relation to their debt position. We have implemented this policy believing it to be
						in line with our ethos, and to meet the requirements of the National Consumer Credit Protection Act."
					</Typography>
					<Typography variant="h4">What is Financial Difficulty?</Typography>
					<Typography variant="body1">
						Our customers who are experiencing Financial Difficulty are those unable to meet their present financial commitments without
						sacrificing their ability to meet basic living needs (such as rent and food).
					</Typography>
					<Typography variant="body1">Financial Difficulty is different for everyone, some contributing factors could be</Typography>
					<ul>
						<li>Employment situation;</li>
						<li>Illness or injury;</li>
						<li>Family circumstances;</li>
						<li>Addiction in all its forms;</li>
						<li>Natural disaster; or</li>
						<li>Indebtedness.</li>
					</ul>
					<Typography variant="body1">
						Options should be discussed with the customer if any of the above contributing factors are identified during a conversation or
						through other means.
					</Typography>
					<Typography variant="body1">Customers have the right to request hardship orally (informally) or in writing.</Typography>
					<Typography variant="h4">Application</Typography>
					<ul>
						<li>To assist customers in identifying their financial circumstances and options;</li>
						<li>To deliver consistent, fair options, delivered with integrity and respect;</li>
						<li>To respond to individual customer needs;</li>
						<li>To assist customers to proactively manage their accounts;</li>
						<li>To provide incentives to encourage customers to establish and maintain agreed payment plans;</li>
						<li>To identify that for some customers experiencing financial difficulty may require additional assistance;</li>
						<li>
							To fulfil and exceed the statutory requirements of the ASIC and ACCC Guidelines, ASIC Guidelines and Hardship Guidelines
							in the development of a Hardship Policy in consultation with relevant customer; and
						</li>
						<li>To effectively manage debt on behalf of our customers and their stakeholders.</li>
					</ul>
					<Typography variant="h4">Basic Standards</Typography>
					<Typography variant="body1">
						If a customer informs Australian Receivables Finance that they are experiencing payment difficulties, Australian Receivables
						Finance will discuss their ability to service their debt.
					</Typography>
					<Typography variant="body1">When undertaking this conversation, we will be mindful of:</Typography>
					<ul>
						<li>The customer’s present debt position;</li>
						<li>Information that will assist both the customer and the business come to an agreement regarding payments;</li>
						<li>Number of children and/or dependants (financial responsibility);</li>
						<li>Contributing factors to current position;</li>
						<li>
							Information that might be useful for customer in present circumstances e.g. eligibility for Government funded concessions
							(health care card, social security benefit, pensioner);
						</li>
						<li>Recommendations given by financial counsellor or relevant consumer representative organisation; and</li>
						<li>
							Other issues that will be considered include: any previous dealings with Australian Receivables Finance, medical
							conditions, disability, family circumstances and domestic violence as contributing factors.
						</li>
					</ul>
					<Typography variant="h4">Rights of Customers</Typography>
					<Typography variant="body1">
						Each customer experiencing Financial Difficulty or Hardship will be treated with sensitivity on a case-by-case basis and will
						have the right to:
					</Typography>
					<ul>
						<li>Receive information on Australian Receivables Finance Hardship Policy;</li>
						<li>Be informed of the various payment methods available;</li>
						<li>Renegotiate the amount of their instalment if their circumstances change;</li>
						<li>
							Be provided with information regarding any independent financial and other counselling services that are available to
							them;
						</li>
						<li>
							Not be subject to any legal action or any additional debt recovery costs, whilst continuing to make payments according to
							our agreement;
						</li>
						<li>
							Make an offer to pay by instalments based on what they are able to afford, while meeting their individual and family
							needs.
						</li>
					</ul>
					<Typography variant="h4">Staff capability and Training</Typography>
					<Typography variant="body1">
						Australian Receivables Finance will ensure its employees are supported and trained to support customers experiencing financial
						difficulty or hardship with sensitivity and respect. They will be informed about Australian Receivables Finance 's legal
						responsibilities when working with customers.
					</Typography>
					<Typography variant="body1">
						We will on an annual basis review policies, processes and training to be confident we are living our vision and meeting the
						needs of our staff, our customers and business partners.
					</Typography>
				</Spacing>
			</Container>
			<ARFCustomerFooter />
		</Box>
	);
};

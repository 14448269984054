import React from 'react';

export const Home = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 2L7.717 6.068V4.105h-2.57v4.403L2 11.495l1.153 1.325 1.137-1.08v9.365c0 .495.384.895.857.895H10v-4.999h4V22h4.853c.473 0 .857-.4.857-.895v-9.364l1.137 1.08L22 11.496 12 2zm2 12h-4v-4h4v4z"
			fillRule="evenodd"
		/>
	</svg>
);

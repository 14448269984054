import { AppBar, Button, Container, IconButton, Link, MenuItem, Toolbar, useScrollTrigger } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from 'material-ui-popup-state/HoverMenu';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';
import { Logo } from '../../../../elements/Branding/Logo';
import { WrapWithLink } from '../../../../elements/WrapWithLink/wrapWithLink';
import { ArfRoute, BusinessRoutes, WebRoutes } from '../../../../enums/routerPath';
import { spacingUnits } from '../../../../theme/theme';
import useStyles from './header.styles';
import { LinesStacked } from '../../../../elements/PantheraIcon/LinesStacked';
import { CustomerMobileHeader } from './elements/Mobile/mobileHeader';

type CustomerHeaderProps = {
	className?: string;
};

export const CustomerHeader: React.FC<CustomerHeaderProps> = ({ className }) => {
	const styles = useStyles();
	const helpMenuState = usePopupState({ variant: 'popover', popupId: 'helpMenu' });
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});
	const [mobileOpen, setMobileOpen] = React.useState(false);

	return (
		<>
			{mobileOpen && <CustomerMobileHeader onClose={() => setMobileOpen(false)} />}
			<AppBar className={clsx(styles.root, className, scrollTrigger ? styles.scrolled : undefined)} position="relative" elevation={0}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<WrapWithLink
							className={styles.logo}
							LinkProps={{
								to: WebRoutes.HOME.path,
							}}
						>
							<Logo />
						</WrapWithLink>
						<nav className={clsx(styles.nav, styles.sectionDesktop)}>
							<div className={styles.navLink}>
								<Link component={NavLink} to={WebRoutes.HOW_IT_WORKS.path} underline="none" exact>
									How it works
								</Link>
							</div>
							<div className={styles.navLink}>
								<Link component={NavLink} to={WebRoutes.SUPPORT.path} underline="none" exact>
									Help
								</Link>
							</div>
							<div className={clsx(styles.navLink, styles.navLinkDropdown)}>
								<Button
									color="primary"
									className={styles.dropdown}
									disableRipple
									endIcon={<ArrowDropDownIcon />}
									{...bindHover(helpMenuState)}
								>
									About
								</Button>
								<Menu
									autoFocus={false}
									PopoverClasses={{ paper: styles.popover }}
									{...bindMenu(helpMenuState)}
									getContentAnchorEl={null}
									anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
									transformOrigin={{ vertical: 'top', horizontal: 'center' }}
									disableScrollLock={true}
								>
									<div className={styles.menuWrapper}>
										<MenuItem onClick={helpMenuState.close} component={NavLink} to={WebRoutes.ABOUT.path} exact>
											About Us
										</MenuItem>
										<MenuItem onClick={helpMenuState.close} component={NavLink} to={WebRoutes.CONTACT.path} exact>
											Contact
										</MenuItem>
									</div>
								</Menu>
							</div>
							<div className={styles.navLink}>
								<Link component={NavLink} to={ArfRoute.HOME.path} underline="none" exact>
									ARF
								</Link>
							</div>
						</nav>
						<div className={styles.grow} />
						<div className={styles.sectionDesktop}>
							<Button
								color="primary"
								size="medium"
								variant="outlined"
								component={RouterLink}
								to={BusinessRoutes.HOME.path}
								style={{ marginRight: spacingUnits(2) }}
							>
								Business
							</Button>
							<Button color="secondary" size="medium" variant="contained" component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
								Manage my account
							</Button>
						</div>
						<div className={styles.sectionMobile}>
							<Button
								color="secondary"
								size="small"
								variant="contained"
								style={{ marginRight: spacingUnits(0.5) }}
								component={RouterLink}
								to={WebRoutes.CUSTOMERS.path}
							>
								Manage my account
							</Button>
							<IconButton edge="end" color="primary" aria-label="menu" onClick={() => setMobileOpen(true)}>
								<LinesStacked style={{ width: 24 }} />
							</IconButton>
						</div>
					</Toolbar>
				</Container>
			</AppBar>
		</>
	);
};

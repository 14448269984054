import React from 'react';

export const LinesStacked = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M2 5h19.038v1.73H2V5zm0 6.058h19.038v1.73H2v-1.73zm0 6.057h19.038v1.731H2v-1.73z"
			fillRule="evenodd"
		/>
	</svg>
);

import { Typography } from '@material-ui/core';
import React from 'react';

const BPayTerms = () => (
	<>
		<Typography variant="subtitle1">Initial Terms of Agreement</Typography>
		<Typography variant="body1">
			A payment arrangement has been entered into between yourself and ARL Collect. You will be required to make your payments promptly on or
			before each due date.
		</Typography>
		<Typography variant="body1">
			Please note that the term of your arrangement may vary according to any applicable fees or interest incurred on your account.
		</Typography>
		<Typography variant="body1">By accepting this method of payment, you agree to receive reminders for the scheduled payments.</Typography>
		<Typography variant="body1">Any drawing that is returned or dishonoured may incur administration fees payable to ARL Collect.</Typography>
		<Typography variant="subtitle1">Your commitment to us</Typography>
		<Typography variant="body1">It is your responsibility to ensure that:</Typography>
		<ul>
			<li>Payment is made on the scheduled drawing date for the scheduled amount.</li>
			<li>That you have sufficient funds in your nominated account to make payment.</li>
			<li>That you will advise us to make any changes to the arrangement.</li>
		</ul>
		<Typography variant="subtitle1">Your Rights</Typography>
		<Typography variant="subtitle2">Changes to the arrangement</Typography>
		<Typography variant="body1">
			If you want to make changes to the arrangement, please contact us in writing at least 3 days before the next scheduled payment date. These
			changes may include:
		</Typography>
		<ul>
			<li>Deferring the arrangement; or</li>
			<li>Altering the schedule; or</li>
			<li>Stopping an individual payment; or</li>
			<li>Suspending the arrangement; or</li>
			<li>Cancelling the arrangement</li>
		</ul>
		<Typography variant="subtitle2">Enquiries</Typography>
		<Typography variant="body1">
			Please direct all enquiries to us providing at least 3 business days prior to the next scheduled drawing date. All communication address
			to us should include your full name and customer reference number.
		</Typography>
	</>
);

export default BPayTerms;

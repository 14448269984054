import React from 'react';

export const CaretDownSm = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M8.604 10h6.792c.534 0 .804.685.431 1.093l-3.396 3.713a.576.576 0 0 1-.863 0l-3.395-3.713C7.8 10.685 8.07 10 8.603 10"
			fillRule="evenodd"
		/>
	</svg>
);

import React from 'react';

export const CircleTimesSolid = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 13.005L15.005 17 12 13.995 8.995 17 7 15.005 10.005 12 7 8.995 8.995 7 12 10.005 15.005 7 17 8.995 13.995 12 17 15.005z"
			fillRule="evenodd"
		/>
	</svg>
);

import { Button, Card, Container, Grid, Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { ButtonStack } from '../../../../elements/ButtonStack/buttonStack';
import { CTAHeadings } from '../../../../elements/CTAHeadings/cTAHeadings';
import { PaymentAmountForm } from '../../../../elements/Forms/elements/PaymentAmountForm/paymentAmountForm';
import { PaymentMethods } from '../../../../elements/Forms/elements/PaymentMethods/paymentMethods';
import { SchedulePaymentForm } from '../../../../elements/Forms/elements/SchedulePaymentForm/schedulePaymentForm';
import { SpinnerButton } from '../../../../elements/SpinnerButton/spinnerButton';
import {
	AvailablePaymentMethod,
	NegotiationModal,
	NegotiationOffer,
	PaymentFrequency,
	PaymentStep,
	PaymentType,
} from '../../../../enums/paymentForm';
import { AccountRoutes, PaymentRoutes } from '../../../../enums/routerPath';
import { generatePaymentFrequencyButtons } from '../../../../services/helpers/paymentForm.helpers';
import {
	clearPaymentAmountError,
	clearPaymentMethod,
	fetchArrangementCheck,
	setActiveStep,
	setNegotiationModalOpen,
	setPaymentAmount,
	setPaymentAmountError,
	setPaymentFrequency,
	setPaymentMethod,
	setPaymentType,
} from '../../../../store/features/paymentForm/paymentFormSlice';
import { history } from '../../../../store/history';
import { RootState } from '../../../../store/rootReducer';
import { DashboardLoader } from '../../elements/DashboardLoader/dashboardLoader';
import { AcceptOfferModal } from './elements/AcceptOfferModal/acceptOfferModal';
import { AlmostThereOffer } from './elements/AlmostThereOffer/almostThereOffer';
import { LowValueOffer } from './elements/LowValueOffer/lowValueOffer';
import { SavingsOffer } from './elements/SavingsOffer/savingsOffer';
import { CantAffordModal } from './elements/CantAffordModal/cantAffordModal';
import useStyles from './paymentArrangement.styles';

const steps = [
	{
		title: 'Select a payment method',
		subtitle: 'How would you like to pay?',
	},
	{
		title: 'Select a payment date',
		subtitle: 'When do you want to make a payment?',
	},
	{
		title: 'Enter payment amount',
		subtitle: 'How much do you want to pay?',
	},
	{
		title: ", while your amount has been accepted, I've got an idea",
		subtitle: 'The amount you entered is very close to the total amount owing on this account',
	},
];

export const PaymentArrangement: React.FC = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const {
		paymentMethod,
		activeStep,
		outstanding,
		paymentAmount,
		paymentAmountError,
		paymentFrequency,
		paymentMethods,
		paymentFrequencies,
		negotiationModalOpen,
		negotiationOfferScreen,
		minAmounts,
		loading,
		minPaymentLoading,
		returnRoute,
		hasFixedAmount,
	} = useSelector(
		(state: RootState) => ({
			paymentMethod: state.paymentForm.paymentMethod,
			activeStep: state.paymentForm.activeStep,
			outstanding: state.customer.customerDetails?.outstandingAmt,
			paymentAmount: state.paymentForm.paymentAmount,
			paymentFrequency: state.paymentForm.paymentFrequency,
			paymentAmountError: state.paymentForm.paymentAmountError,
			paymentMethods: state.paymentMethod.methods,
			paymentFrequencies: state.paymentFrequency.frequencies,
			negotiationModalOpen: state.paymentForm.negotiationModalOpen,
			negotiationOfferScreen: state.paymentForm.negotiationOfferScreen,
			minAmounts: state.paymentForm.minAmounts,
			loading: false,
			minPaymentLoading: state.paymentForm.loading,
			returnRoute: state.paymentForm.returnRoute,
			hasFixedAmount: state.paymentForm.hasFixedAmount,
		}),
		shallowEqual,
	);
	const isFirstStep = activeStep === PaymentStep.PAYMENT_METHOD;
	const isLastStep = activeStep === steps.length;

	useEffect(() => {
		dispatch(setPaymentType(PaymentType.ARRANGEMENT));
	}, [dispatch]);

	useEffect(() => {
		if (!paymentFrequency && paymentFrequencies) {
			// Set the default frequency
			let defaultFrequency: PaymentFrequency = PaymentFrequency.WEEKLY;
			if (paymentFrequencies.some((frequency) => frequency.Name === PaymentFrequency.WEEKLY)) {
				defaultFrequency = PaymentFrequency.WEEKLY;
			} else if (paymentFrequencies.some((frequency) => frequency.Name === PaymentFrequency.FORTNIGHTLY)) {
				defaultFrequency = PaymentFrequency.FORTNIGHTLY;
			} else if (paymentFrequencies.some((frequency) => frequency.Name === PaymentFrequency.ONE_OFF)) {
				defaultFrequency = PaymentFrequency.ONE_OFF;
			}
			dispatch(setPaymentFrequency(paymentFrequencies.find((frequency) => frequency.Name === defaultFrequency)));
		}
	}, [dispatch, paymentFrequency, paymentFrequencies]);

	useEffect(() => {
		if (activeStep === PaymentStep.PAYMENT_AMOUNT) {
			document.getElementById('bodyContent')?.classList.add('whiteBackground-sm', 'brandmarkHidden-sm');
		} else {
			document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm', 'brandmarkHidden-sm');
		}
		return () => document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm', 'brandmarkHidden-sm');
	}, [activeStep]);

	const paymentAmountSchema = Yup.object().shape({
		paymentAmount: Yup.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required('Enter a payment amount')
			.min(1, 'Minimum payment amount is $1')
			.max(outstanding ?? 0, 'Payment amount exceeds outstanding balance. The amount has been updated to the total balance.'),
	});

	const renderStepContent = (step: PaymentStep) => {
		switch (step) {
			case PaymentStep.PAYMENT_METHOD:
				return (
					<>
						<CTAHeadings subtitle={steps[PaymentStep.PAYMENT_METHOD - 1].subtitle} title={steps[PaymentStep.PAYMENT_METHOD - 1].title} />
						<PaymentMethods
							isArrangement={true}
							selected={paymentMethod}
							onSelect={(updatedPaymentMethod) =>
								dispatch(setPaymentMethod(paymentMethods?.find((method) => method.Name === updatedPaymentMethod)))
							}
						/>
					</>
				);
			case PaymentStep.PAYMENT_DATE:
				return (
					<>
						<CTAHeadings subtitle={steps[PaymentStep.PAYMENT_METHOD - 1].subtitle} title={steps[PaymentStep.PAYMENT_METHOD - 1].title} />
						<PaymentMethods selected={paymentMethod} onRemove={() => dispatch(clearPaymentMethod())} />
						<CTAHeadings subtitle={steps[PaymentStep.PAYMENT_DATE - 1].subtitle} title={steps[PaymentStep.PAYMENT_DATE - 1].title} />
						<SchedulePaymentForm />
					</>
				);
			case PaymentStep.PAYMENT_AMOUNT:
				return (
					<>
						<Hidden mdUp>
							<Typography variant="h5">{steps[PaymentStep.PAYMENT_AMOUNT - 1].subtitle}</Typography>
						</Hidden>
						<Hidden smDown>
							<CTAHeadings
								subtitle={steps[PaymentStep.PAYMENT_AMOUNT - 1].subtitle}
								title={steps[PaymentStep.PAYMENT_AMOUNT - 1].title}
							/>
						</Hidden>
						<PaymentAmountForm
							paymentFrequencyButtons={generatePaymentFrequencyButtons(paymentFrequencies ?? [])}
							outstandingAmount={outstanding ?? 0}
							paymentAmount={paymentAmount.toString()}
							paymentFrequency={paymentFrequency}
							updatePaymentAmount={(updatedPaymentAmount) => {
								dispatch(clearPaymentAmountError());
								dispatch(setPaymentAmount(updatedPaymentAmount));
							}}
							updatePaymentFrequency={(updatedPaymentFrequencyID) => {
								dispatch(
									setPaymentFrequency(
										paymentFrequencies?.find((frequency) => frequency.PeriodicFrequencyID === updatedPaymentFrequencyID),
									),
								);
							}}
							paymentAmountError={paymentAmountError}
							onEnterKeyPress={handleContinue}
						/>
					</>
				);
			case PaymentStep.PAYMENT_OFFER:
				return (
					<>
						{negotiationOfferScreen === NegotiationOffer.LOW && paymentFrequency && (
							<LowValueOffer
								outstanding={outstanding ?? 0}
								paymentFrequencyID={paymentFrequency.PeriodicFrequencyID}
								minPay={minAmounts[paymentFrequency?.Name as PaymentFrequency] ?? 0}
								paymentAmount={paymentAmount}
								onCantAfford={cantAffordModalShow}
								onBack={handleBack}
								onSetup={handleOfferContinue}
							/>
						)}
						{negotiationOfferScreen === NegotiationOffer.ALMOST && paymentFrequency && (
							<AlmostThereOffer
								outstanding={outstanding ?? 0}
								paymentFrequencyID={paymentFrequency.PeriodicFrequencyID}
								minPay={minAmounts[paymentFrequency?.Name as PaymentFrequency] ?? 0}
								paymentAmount={paymentAmount}
								onCantAfford={handleBack}
								onBack={handleBack}
								onSetup={handleOfferContinue}
							/>
						)}
						{negotiationOfferScreen === NegotiationOffer.SAVINGS && paymentFrequency && (
							<SavingsOffer
								outstanding={outstanding ?? 0}
								paymentFrequencyID={paymentFrequency.PeriodicFrequencyID}
								paymentAmount={paymentAmount}
								onContinue={handleOfferContinue}
								onBack={handleBack}
							/>
						)}
					</>
				);
			default:
				// This will never be reached.
				return <div>Not Found</div>;
		}
	};

	const handleBack = () => {
		if (isFirstStep) {
			if (returnRoute) {
				history.replace(returnRoute);
			} else {
				history.push(AccountRoutes.OVERVIEW.path);
			}
		} else if (activeStep === PaymentStep.PAYMENT_DATE) {
			dispatch(clearPaymentMethod());
		} else {
			dispatch(setActiveStep(activeStep - 1));
		}
	};

	const routeToPayment = () => {
		dispatch(setNegotiationModalOpen(undefined));
		if (paymentMethod?.Name === AvailablePaymentMethod.BPAY) {
			history.push(PaymentRoutes.PAYMENT_AGREEMENT.path);
		} else if (paymentMethod?.Name === AvailablePaymentMethod.DIRECT) {
			history.push(PaymentRoutes.PAYMENT_DD.path);
		} else {
			history.push(PaymentRoutes.PAYMENT_CC.path);
		}
	};

	const cantAffordModalShow = () => {
		dispatch(setActiveStep(PaymentStep.PAYMENT_AMOUNT));
		dispatch(setNegotiationModalOpen(NegotiationModal.CANT_AFFORD));
	};

	const handleOfferContinue = (acceptedAmount: number) => {
		if (paymentFrequency) {
			dispatch(setPaymentAmount(acceptedAmount.toString()));
			// Hide the offer page if the user accepts the offer
			dispatch(setActiveStep(PaymentStep.PAYMENT_AMOUNT));
			routeToPayment();
		}
	};

	const handleContinue = async () => {
		if (activeStep === PaymentStep.PAYMENT_AMOUNT) {
			try {
				await paymentAmountSchema.validate({ paymentAmount });
				dispatch(fetchArrangementCheck(paymentAmount, paymentFrequency));
			} catch (err) {
				if (err instanceof Yup.ValidationError) {
					// Set payment amount to total
					if (err.type === 'max') {
						dispatch(setPaymentAmount(outstanding?.toString() ?? ''));
					}
					dispatch(setPaymentAmountError(err.message));
				}
			}
		} else if (hasFixedAmount && activeStep === PaymentStep.PAYMENT_DATE) {
			routeToPayment();
		} else {
			dispatch(setActiveStep(activeStep + 1));
		}
	};

	return (
		<>
			<CantAffordModal open={negotiationModalOpen === NegotiationModal.CANT_AFFORD} />
			<AcceptOfferModal open={negotiationModalOpen === NegotiationModal.WE_ACCEPT} routeToPayment={routeToPayment} />
			{loading && <DashboardLoader />}
			{!loading && (
				<>
					{isLastStep && renderStepContent(activeStep)}
					{!isLastStep && (
						<>
							<Card className={clsx(styles.root, activeStep === PaymentStep.PAYMENT_OFFER && styles.offer)} raised>
								{renderStepContent(activeStep)}
								<Hidden smDown>
									<Container disableGutters={true} className={styles.container}>
										<Grid alignItems="center" container direction="row" justifyContent="center" spacing={2}>
											<Grid item xs={isFirstStep ? 12 : 6}>
												<Button color="primary" fullWidth size="large" variant="outlined" onClick={handleBack}>
													Back
												</Button>
											</Grid>
											{!isFirstStep && (
												<Grid item xs={6}>
													<SpinnerButton
														data-cy="btnPaContinue"
														color="secondary"
														fullWidth
														size="large"
														variant="contained"
														onClick={handleContinue}
														loading={minPaymentLoading}
													>
														Continue
													</SpinnerButton>
												</Grid>
											)}
										</Grid>
									</Container>
								</Hidden>
							</Card>
							<Hidden mdUp>
								<ButtonStack>
									{!isFirstStep && (
										<SpinnerButton
											color="secondary"
											fullWidth
											size="large"
											variant="contained"
											onClick={handleContinue}
											loading={minPaymentLoading}
										>
											Continue
										</SpinnerButton>
									)}

									<Button color="primary" fullWidth size="large" variant="outlined" onClick={handleBack}>
										Back
									</Button>
								</ButtonStack>
							</Hidden>
						</>
					)}
				</>
			)}
		</>
	);
};

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { WebRoutes } from '../../enums/routerPath';

export interface AuthenticatedRouteProps extends RouteProps {
	isAuthenticated: boolean;
	redirectPath?: string;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
	isAuthenticated,
	redirectPath,
	...props
}) => {
	return isAuthenticated ? (
		<Route {...props} />
	) : (
		<Redirect to={redirectPath ?? WebRoutes.HOME.path} />
	);
};

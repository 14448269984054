// DO NOT SORT imports, otherwise throws error 'Cannot read property 'user' of undefined'
/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// IE11 needs 'jsxRuntime classic' for this initial file which means that 'React' needs to be in scope
// issue: https://github.com/facebook/create-react-app/issues/9906
import 'fast-text-encoding/text';
import '@microsoft/applicationinsights-shims';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enAU from 'date-fns/locale/en-AU';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './store/store';
import theme from './theme';
import Routing from './routes/routes';
import { history } from './store/history';
import { ScrollToTop } from './elements/ScrollToTop/scrollToTop';
import { GoogleAnalytics } from './elements/GoogleAnalytics/googleAnalytics';
import { TelemetryTracking } from './elements/TelemetryProvider/telemetryProvider';
import { ai, getAppInsights } from './elements/TelemetryProvider/telemetryService';

// This way if we ever change test frameworks we won't have to search through the code.
export const isTestMode = window.Cypress;

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={enAU}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<HelmetProvider>
							<Helmet defaultTitle={window.config.REACT_APP_COMPANY} />
							<CssBaseline />
							<Router history={history}>
								<TelemetryTracking instrumentationKey={window.config.REACT_APP_INSIGHTS_KEY} after={() => getAppInsights()}>
									<AppInsightsErrorBoundary appInsights={ai.reactPlugin} onError={() => <div>I believe something went wrong</div>}>
										<GoogleAnalytics />
										<ScrollToTop />
										<Routing />
									</AppInsightsErrorBoundary>
								</TelemetryTracking>
							</Router>
						</HelmetProvider>
					</PersistGate>
				</Provider>
			</MuiPickersUtilsProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

if (isTestMode) {
	window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

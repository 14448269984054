import React from 'react';

export const ChevronRight = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M6.439 18.439l6.877-6.878L6.44 4.684 8.56 2.56l9 9-9 9z"
			fillRule="evenodd"
		/>
	</svg>
);

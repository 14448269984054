/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { List, ListItem, ListProps, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import useStyles from './buttonStack.styles';

type ButtonStackProps = {
	className?: string;
};

export const ButtonStack: React.FC<
	ButtonStackProps & React.PropsWithChildren<ListProps>
> = ({ className, children, ...props }) => {
	const styles = useStyles();
	return (
		<List
			className={clsx(
				styles.root,
				React.Children.count(children) === 1 &&
					(children as React.ReactElement)?.type !== React.Fragment &&
					styles.rootSolo,
				className,
			)}
			{...props}
		>
			{(children as React.ReactElement)?.type !== React.Fragment &&
				React.Children.map(
					children as React.ReactElement<ButtonProps>,
					(child, index) =>
						React.isValidElement(child) ? (
							<ListItem
								key={index}
								className={styles.listItem}
								disableGutters
							>
								{React.cloneElement(child, {
									className: clsx(
										child?.props.className,
										styles.buttonWrapper,
									),
								})}
							</ListItem>
						) : null,
				)}
			{(children as React.ReactElement)?.type === React.Fragment &&
				React.Children.map(
					(children as React.ReactElement)?.props
						.children as React.ReactElement<ButtonProps>,
					(fragChild, fragIndex) =>
						React.isValidElement(fragChild) ? (
							<ListItem
								key={fragIndex}
								className={styles.listItem}
								disableGutters
							>
								{React.cloneElement(fragChild, {
									className: clsx(
										fragChild?.props.className,
										styles.buttonWrapper,
									),
								})}
							</ListItem>
						) : null,
				)}
		</List>
	);
};

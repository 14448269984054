import { makeStyles } from '@material-ui/core';
import { brandMexicanRed, getThemeStyle, spacingUnits } from '../../../../theme/theme';

export default makeStyles(
	(theme) => ({
		grow: {
			[theme.breakpoints.up('md')]: {
				flexGrow: 1,
			},
		},
		root: {
			color: getThemeStyle('paletteCommonWhite'),
			backgroundColor: brandMexicanRed,
			paddingTop: spacingUnits(4),
			paddingBottom: spacingUnits(4),

			'& .MuiDivider-root': {
				margin: [[spacingUnits(2.5), 0]],
				backgroundColor: 'rgba(255,255, 255, 0.3)',
			},
		},
		footerLinks: {
			'& .MuiGrid-item': {
				marginLeft: spacingUnits(2.5),
				marginRight: spacingUnits(2.5),

				[theme.breakpoints.down('md')]: {
					marginTop: spacingUnits(1),
					marginBottom: spacingUnits(1),
					marginLeft: spacingUnits(1.5),
					marginRight: spacingUnits(1.5),
				},
			},

			'& .MuiGrid-item:first-child': {
				marginLeft: 0,
			},
			'& .MuiGrid-item:last-child': {
				marginRight: 0,
			},
			[theme.breakpoints.down('md')]: {
				justifyContent: 'center',
			},
		},
		logo: {
			display: 'flex',
			[theme.breakpoints.down('md')]: {
				justifyContent: 'center',
			},

			'& svg': {
				height: 50,
			},
		},
		navLink: {
			color: getThemeStyle('paletteCommonWhite'),
			fontSize: '1.6rem',
			lineHeight: '1.8rem',
			fontWeight: 500,
		},
		accreditations: {
			textTransform: 'uppercase',
			fontSize: '1.2rem',
			lineHeight: '1.4rem',
			letterSpacing: 1.2,
			fontWeight: 500,
			opacity: 0.8,
		},
		copyright: {
			fontSize: '1.4rem',
			textAlign: 'center',
		},
		contact: {
			display: 'flex',
			justifyContent: 'center',
			whiteSpace: 'nowrap',
			marginRight: spacingUnits(0.5),
			marginLeft: spacingUnits(0.5),

			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: spacingUnits(0.5),
				marginBottom: spacingUnits(0.5),
				marginRight: spacingUnits(0.5),
				marginLeft: spacingUnits(0.5),
				whiteSpace: 'normal',
			},

			'& > span:first-child': {
				fontSize: '1.4rem',
				lineHeight: '26px',
			},

			'& .MuiTypography-root:not(:first-child)': {
				fontSize: '1.6rem',
				marginLeft: spacingUnits(1),
				fontWeight: 500,
				lineHeight: '26px',
			},
		},
	}),
	{ name: 'customerFooter' },
);

import React from 'react';

export const Visa = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 62 20" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				fill="#26337A"
				d="M22.157 19.547L25.23.473h4.915l-3.074 19.074h-4.915M44.9.94c-.972-.382-2.5-.8-4.405-.8-4.859 0-8.28 2.589-8.308 6.295-.03 2.742 2.441 4.271 4.305 5.182 1.915.935 2.558 1.532 2.55 2.368-.013 1.277-1.528 1.862-2.941 1.862-1.968 0-3.013-.288-4.63-1l-.632-.304-.687 4.27c1.146.531 3.27.992 5.476 1.017 5.164 0 8.52-2.558 8.559-6.516.017-2.174-1.292-3.822-4.129-5.182-1.717-.885-2.771-1.47-2.76-2.366.002-.792.891-1.641 2.816-1.641a8.62 8.62 0 0 1 3.679.733l.44.218L44.9.94M51.444 12.784c.407-1.096 1.958-5.331 1.958-5.331-.029.05.405-1.105.653-1.82l.332 1.645s.943 4.553 1.137 5.506h-4.08zM57.507.492H53.71c-1.178 0-2.06.338-2.576 1.581l-7.299 17.48h5.162s.843-2.351 1.034-2.867c.563 0 5.579.01 6.295.01.147.665.598 2.858.598 2.858h4.562L57.507.492zM18.033.489l-4.81 13.004-.515-2.644c-.897-3.045-3.687-6.346-6.809-8L10.3 19.53l5.202-.003L23.242.489h-5.209"
			/>
			<path
				d="M8.757.476H.831L.766.87c6.167 1.58 10.248 5.394 11.942 9.978l-1.723-8.764C10.688.876 9.825.518 8.757.475"
				fill="#EC982D"
			/>
		</g>
	</svg>
);

import React from 'react';
import { AssistantCTAHollow } from '../../../../../../elements/AssistantCTAHollow/assistantCTAHollow';

export const PAThanksHollow = () => {
	return (
		<AssistantCTAHollow
			subtitle="Thank you so much for setting up your payment plan, we really appreciate it. You can easily keep track of all your payment details, schedules, transaction history, reminders and more in the dashboard."
			title="Now what? Now you can relax!"
		/>
	);
};

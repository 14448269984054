import React from 'react';

export const HdPaymentTerminal = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 80 80" {...props}>
		<g fillRule="evenodd">
			<path d="M17.285 39.426h26.712V27.284H17.285v12.142zm-2.428 2.428h31.568v-17H14.857v17zM19.714 50.353h2.429v-2.428h-2.429v2.428zm-2.429 2.428h7.285v-7.285h-7.285v7.285zM29.426 50.353h2.429v-2.428h-2.429v2.428zm-2.427 2.428h7.285v-7.285h-7.285v7.285zM39.14 50.353h2.429v-2.428H39.14v2.428zm-2.428 2.428h7.285v-7.285h-7.285v7.285zM19.714 60.067h2.429v-2.43h-2.429v2.43zm-2.429 2.427h7.285V55.21h-7.285v7.284zM29.426 60.067h2.429v-2.43h-2.429v2.43zm-2.427 2.427h7.285V55.21h-7.285v7.284zM39.14 60.067h2.429v-2.43H39.14v2.43zm-2.428 2.427h7.285V55.21h-7.285v7.284zM19.714 69.78h2.429v-2.428h-2.429v2.428zm-2.429 2.428h7.285v-7.285h-7.285v7.285zM29.426 69.78h2.429v-2.428h-2.429v2.428zm-2.427 2.428h7.285v-7.285h-7.285v7.285zM39.14 69.78h2.429v-2.428H39.14v2.428zm-2.428 2.428h7.285v-7.285h-7.285v7.285z" />
			<path d="M39.14 36.998h2.429v-2.43H39.14zM29.426 36.998h7.285v-2.43h-7.285zM22.142 12.713H39.14v-2.428H22.142zM36.712 17.57h2.429v-2.428h-2.429z" />
			<path d="M58.224 53.995h-7.063l.121-1.214V18.784h15.784c.671 0 1.214.544 1.214 1.214v32.914c0 .6-.485 1.083-1.082 1.083h-8.974zM46.76 73.665a2.419 2.419 0 0 1-2.416 2.185H16.94a2.419 2.419 0 0 1-2.416-2.186l-2.095-20.883v-37.64a2.432 2.432 0 0 1 2.43-2.428h2.427v7.285h-2.428v2.43h31.568v-2.43h-2.429v-7.285h2.43a2.432 2.432 0 0 1 2.427 2.428l.007 37.52-2.1 21.003zM19.713 5.427h2.43v2.43h2.427v-2.43h2.428v2.43h2.428v-2.43h2.43v2.43h2.428v-2.43h2.427v2.43h2.43v-2.43h2.428v14.57H19.713V5.428zM70.708 25.5v-5.502a3.642 3.642 0 0 0-3.642-3.642H51.282v-1.215a4.857 4.857 0 0 0-4.857-4.856h-2.429V3h-26.71v7.285h-2.429A4.862 4.862 0 0 0 10 15.141l.007 37.761 2.1 21.004a4.855 4.855 0 0 0 4.83 4.373h27.408a4.856 4.856 0 0 0 4.831-4.374l1.743-17.48H67.46a3.248 3.248 0 0 0 3.248-3.25V25.5z" />
			<path d="M56.138 23.641c.59 0 1.162.225 1.616.632l.813.733.812-.733a2.426 2.426 0 0 1 1.616-.632 2.431 2.431 0 0 1 2.428 2.428 2.43 2.43 0 0 1-2.428 2.428 2.42 2.42 0 0 1-1.616-.632l-.812-.732-.813.732a2.419 2.419 0 0 1-1.616.632 2.43 2.43 0 0 1-2.428-2.428 2.43 2.43 0 0 1 2.428-2.428m0 7.285c.856 0 1.693-.23 2.43-.663a4.791 4.791 0 0 0 2.427.663 4.862 4.862 0 0 0 4.857-4.857 4.863 4.863 0 0 0-4.857-4.857c-.856 0-1.693.231-2.428.663a4.797 4.797 0 0 0-2.429-.663 4.862 4.862 0 0 0-4.856 4.857 4.861 4.861 0 0 0 4.856 4.857M53.711 46.71h2.428V33.355h-2.428zM53.711 51.568h2.428v-2.43h-2.428z" />
		</g>
	</svg>
);

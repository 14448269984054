import React from 'react';

export const PhoneCall = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 80 80" {...props}>
		<path
			d="M56.644 65.203c-1.831 1.832-4.34 2.883-6.881 2.883a8.954 8.954 0 0 1-3.716-.793c-19.161-8.658-29.135-23.344-34.128-34.138-1.668-3.606-.866-7.952 1.996-10.813l4.246-4.245 11.059 11.06-7.67 7.668.562 1.009a49.14 49.14 0 0 0 19.107 19.107l1.009.561 7.668-7.669 11.06 11.06-4.312 4.31zM41.706 53.695l-.227-.136a46.236 46.236 0 0 1-15.986-15.985l-.136-.227 8.189-8.19L18.16 13.769l-6.411 6.41c-3.763 3.764-4.812 9.495-2.608 14.261 3.017 6.52 6.907 12.519 11.564 17.829 6.68 7.619 14.783 13.612 24.08 17.814a11.977 11.977 0 0 0 4.966 1.059c3.357 0 6.658-1.376 9.057-3.775l6.474-6.475-15.387-15.386-8.19 8.189zm3.912-18.327h3.075a5.278 5.278 0 0 0-4.92-4.918v3.075a2.218 2.218 0 0 1 1.845 1.843zm7.5 0h3.06c-.177-6.761-5.643-12.228-12.405-12.405v3.061c5.074.176 9.169 4.271 9.345 9.344zm7.483 0h3.06c-.181-10.885-9.002-19.707-19.888-19.887v3.06c9.198.18 16.648 7.629 16.828 16.827zM43.773 8v3.06c13.323.181 24.127 10.985 24.309 24.308h3.06C70.959 20.358 58.782 8.182 43.772 8z"
			fillRule="evenodd"
		/>
	</svg>
);

import React from 'react';

export const QuestionMarkFilled = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 18 18" {...props}>
		<g fillRule="evenodd" stroke="none" strokeWidth="1">
			<g transform="translate(-1231 -374)">
				<g transform="translate(836 144)">
					<g transform="translate(40 229)">
						<g transform="translate(.5)">
							<g transform="translate(218.5)">
								<g transform="translate(135)">
									<path d="M10 1.25a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zm0 14.375a.937.937 0 110-1.875.937.937 0 010 1.875zm.713-4.844v1.563H9.306V9.375h1.319a1.488 1.488 0 000-2.969h-.938c-.819 0-1.484.662-1.487 1.482v.4H6.806v-.4A2.894 2.894 0 019.7 5h.925a2.894 2.894 0 01.075 5.781h.013z" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

import { LoginOptions } from '../../../enums/loginOptions';
import { ARLResponse } from '../../../interceptor/base.api.response';

export interface ILogin extends ARLResponse<{ jwt: string }> {}

interface Customer {
	debtID: number;
	fullName: string;
}

interface JWTAuthBase {
	referenceNumber: number;
	authenticated: boolean;
	iat: number;
	exp: number;
	aud: string;
}

export interface JWTLoginCheck extends JWTAuthBase {
	authTypes: LoginOptions[];
}

export interface JWTLoginAuth extends JWTAuthBase {
	customer: Customer;
	verified: boolean;
	mfa: boolean;
}

export interface JWTLoginConfirm extends JWTAuthBase {
	customer: Customer;
	authenticated: boolean;
}

export interface JWTUserHelper {
	displayName: string;
	displayNameShort: string;
}

export interface JWTUser extends JWTUserHelper {
	referenceNumber: number;
	authTypes?: LoginOptions[];
	customer?: Customer;
	authenticated?: boolean;
	verified?: boolean;
	mfa?: boolean;
	iat: number;
	exp: number;
	aud: string;
}

export enum AuthAPI {
	REFERENCE_CHECK = '/api/login/check',
	VERIFY_ID = '/api/login/auth',
	CONFIRM = '/api/login/confirm',
	MFA = 'api/login/mfa',
}

import React from 'react';

export const CheckThick = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M20.985 3.136l3.536 3.536L9.67 21.52.48 12.328l3.536-3.535 5.657 5.657z"
			fillRule="evenodd"
		/>
	</svg>
);

import React from 'react';

export const Person = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<g fillRule="evenodd">
			<path d="M19.349 16.69l-3.65-1.218a.86.86 0 0 0-.27-.043H8.572a.855.855 0 0 0-.271.043L4.65 16.69A6.793 6.793 0 0 0 0 23.142c0 .474.384.858.857.858h22.286a.857.857 0 0 0 .857-.858 6.793 6.793 0 0 0-4.651-6.453M12 13.714a5.148 5.148 0 0 0 5.143-5.143V5.143a5.143 5.143 0 1 0-10.286 0v3.428A5.148 5.148 0 0 0 12 13.714" />
		</g>
	</svg>
);

import { Typography } from '@material-ui/core';
import React from 'react';

const DirectDebt = () => (
	<>
		<Typography variant="subtitle1">Our Commitment to you</Typography>
		<Typography variant="body1">
			This document outlines our service commitment to you, in respect to the Direct Debit Request (DDR) arrangements made between{' '}
			{window.config.REACT_APP_COMPANY_FULL} and you. It sets out your rights, our commitment to you and your responsibilities to us together
			with where you go for assistance.
		</Typography>
		<Typography variant="subtitle2">Initial Terms of Agreement</Typography>
		<Typography variant="body1">
			In the terms of the Direct Debit Request arrangements made between us and signed by you, we undertake to periodically direct debit your
			nominated account for the agreed amount for payment of your outstanding debt.
		</Typography>

		<Typography variant="subtitle2">Drawing Arrangements</Typography>
		<Typography variant="body1">
			The first drawing under this agreement will be drawn on the nominated day. If any drawing falls due on a non-business day, it will be
			debited to your account the previous business day before the scheduled drawing date. We will give at least 3 days notice when changes to
			the initial terms of the arrangement are made. This notice will state the new amount, dates, frequency and any other changes to the
			initial terms.
		</Typography>

		<Typography variant="subtitle1">Your Rights</Typography>

		<Typography variant="subtitle2">Changes to the Arrangement</Typography>

		<Typography variant="body1">
			If you want to make changes to the drawing arrangements, contact us directly by phone on {window.config.REACT_APP_CONTACT_NUMBER}. These
			changes may include:
		</Typography>
		<ul>
			<li>deferring the drawing; or</li>
			<li>altering the schedule; or</li>
			<li>stopping an individual debit; or</li>
			<li>suspending the DDR; or</li>
			<li>cancelling the DDR Agreement</li>
		</ul>

		<Typography variant="subtitle2">Enquiries</Typography>

		<Typography variant="body1">
			Direct all enquiries to us rather than your financial institution, there should be at least 3 working days prior to the next scheduled
			drawing date. All communication addressed to us should include you full name and customer reference number.
		</Typography>

		<Typography variant="body1">
			All personal customer information held by us will be kept confidential except that information provided to our financial institution to
			initiate the drawing from your nominated account.
		</Typography>

		<Typography variant="subtitle2">Disputes</Typography>

		<Typography variant="body1">
			If you believe that a drawing has been initiated incorrectly, we encourage you to take the matter up directly with us by contacting{' '}
			{window.config.REACT_APP_CONTACT_NUMBER}. If you do not receive a satisfactory response from us to your dispute, contact your financial
			institution who will respond to you with an answer to your claim.
		</Typography>
		<ul>
			<li>within 7 business days (for claims lodged within 12 months of disputed drawing) or</li>
			<li>within 30 business days (for claims lodged more than 12 months of disputed drawing)</li>
		</ul>
		<Typography variant="body1">You will receive a refund of the drawn amount if we can not substantiate the reason for the drawing.</Typography>

		<Typography variant="body1">
			<strong>Note:</strong> Your financial institution will ask you to contact us to resolve your disputed drawing prior to you contacting us.
		</Typography>

		<Typography variant="subtitle1">Your Commitment to us</Typography>
		<ul>
			<li>your nominated account can accept direct debits (your financial institution can confirm this); and</li>
			<li>that on the drawing date there is sufficient cleared funds in the nominated account; and</li>
			<li>that you advise us if the nominated account is transferred or closed.</li>
		</ul>

		<Typography variant="body1">
			If your drawing is returned or dishonoured by your financial institution you will be notified by mail, and your next scheduled drawing
			will take place as agreed. Any drawing that is returned or dishonoured will incur a $10 administration fee payable to ARL Collect and you
			may be liable for other related fees that you may incur from your financial institution.
		</Typography>
	</>
);

export default DirectDebt;

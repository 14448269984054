import { Button, Card, Container, Grid, Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AssistantCTAHollow } from '../../../../elements/AssistantCTAHollow/assistantCTAHollow';
import { ButtonStack } from '../../../../elements/ButtonStack/buttonStack';
import { ComparisonCard } from '../../../../elements/ComparisonCard/comparisonCard';
import { CTAHeadings } from '../../../../elements/CTAHeadings/cTAHeadings';
import { PaymentAmountForm } from '../../../../elements/Forms/elements/PaymentAmountForm/paymentAmountForm';
import { PaymentMethods } from '../../../../elements/Forms/elements/PaymentMethods/paymentMethods';
import { SchedulePaymentForm } from '../../../../elements/Forms/elements/SchedulePaymentForm/schedulePaymentForm';
import { Destination } from '../../../../elements/PantheraIcon/Destination';
import { Flag } from '../../../../elements/PantheraIcon/Flag';
import { AvailablePaymentMethod, PaymentFrequency, PaymentMode, PaymentStep, PaymentType } from '../../../../enums/paymentForm';
import { AccountRoutes, PaymentRoutes } from '../../../../enums/routerPath';
import { WebsiteWorkflow } from '../../../../enums/websiteWorkflow';
import { calculatePercent, generatePaymentButtons } from '../../../../services/helpers/paymentForm.helpers';
import {
	clearPaymentAmountError,
	clearPaymentMethod,
	payNowFlow,
	setActiveStep,
	setInArrangement,
	setPaymentAmount,
	setPaymentAmountError,
	setPaymentFrequency,
	setPaymentMethod,
	setPaymentType,
} from '../../../../store/features/paymentForm/paymentFormSlice';
import { history } from '../../../../store/history';
import { RootState } from '../../../../store/rootReducer';
import { DashboardLoader } from '../../elements/DashboardLoader/dashboardLoader';
import useStyles from './singlePayment.styles';

const steps = [
	{
		title: 'Select a payment method',
		subtitle: 'How would you like to pay?',
	},
	{
		title: 'Select a payment date',
		subtitle: 'When do you want to make a payment?',
	},
	{
		title: 'Enter payment amount',
		subtitle: 'How much do you want to pay?',
	},
	{
		title: ", while your amount has been accepted, I've got an idea",
		subtitle: 'The amount you entered is very close to the total amount owing on this account',
	},
];

export const SinglePayment: React.FC = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const {
		websiteWorkflow,
		merchantFeePercent,
		inArrangement,
		paymentMethod,
		activeStep,
		outstandingAmount,
		paymentMode,
		paymentAmount,
		paymentAmountError,
		displayNameShort,
		paymentMethods,
		ccPayNowOnly,
		loading,
		frequencies,
		returnRoute,
		hasFixedAmount,
	} = useSelector(
		(state: RootState) => ({
			websiteWorkflow: state.customer.customerDetails?.websiteWorkflow,
			merchantFeePercent: state.customer.customerDetails?.merchantFeePercent,
			inArrangement: state.paymentForm.inArrangement,
			paymentMethod: state.paymentForm.paymentMethod,
			activeStep: state.paymentForm.activeStep,
			outstandingAmount: state.customer.customerDetails?.outstandingAmt,
			paymentMode: state.paymentForm.paymentMode,
			paymentAmount: state.paymentForm.paymentAmount,
			paymentAmountError: state.paymentForm.paymentAmountError,
			displayNameShort: state.auth.user?.displayNameShort,
			paymentMethods: state.paymentMethod.methods,
			ccPayNowOnly: state.paymentMethod.ccPayNowOnly,
			loading: state.customer.loading,
			frequencies: state.paymentFrequency.frequencies,
			returnRoute: state.paymentForm.returnRoute,
			hasFixedAmount: state.paymentForm.hasFixedAmount,
		}),
		shallowEqual,
	);
	const isFirstStep = activeStep === PaymentStep.PAYMENT_METHOD;
	const isLastStep = activeStep === steps.length;

	// Check to see if only able to Pay Now
	useEffect(() => {
		if (websiteWorkflow === WebsiteWorkflow.PAYING) {
			dispatch(setInArrangement(true));
		} else {
			dispatch(setInArrangement(false));
		}
	}, [dispatch, websiteWorkflow]);

	useEffect(() => {
		dispatch(setPaymentFrequency(frequencies.find((frequency) => frequency.Name === PaymentFrequency.ONE_OFF)));
	}, [dispatch, frequencies]);

	useEffect(() => {
		dispatch(setPaymentType(PaymentType.SINGLE));
	}, [dispatch]);

	useEffect(() => {
		if (activeStep === PaymentStep.PAYMENT_AMOUNT) {
			document.getElementById('bodyContent')?.classList.add('whiteBackground-sm', 'brandmarkHidden-sm');
		} else {
			document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm', 'brandmarkHidden-sm');
		}
		return () => document.getElementById('bodyContent')?.classList.remove('whiteBackground-sm', 'brandmarkHidden-sm');
	}, [activeStep]);

	const paymentAmountSchema = Yup.object().shape({
		paymentAmount: Yup.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required('Enter a payment amount')
			.min(1, 'Minimum payment amount is $1')
			.max(outstandingAmount ?? 0, 'Payment amount exceeds outstanding balance. The amount has been updated to the total balance.'),
	});

	const renderStepContent = (step: PaymentStep) => {
		switch (step) {
			case PaymentStep.PAYMENT_METHOD:
				return (
					<>
						<CTAHeadings subtitle={steps[PaymentStep.PAYMENT_METHOD - 1].subtitle} title={steps[PaymentStep.PAYMENT_METHOD - 1].title} />
						<PaymentMethods
							selected={paymentMethod}
							onSelect={(updatedPaymentMethod) => {
								if (updatedPaymentMethod === AvailablePaymentMethod.CARD && ccPayNowOnly) {
									dispatch(payNowFlow());
								} else {
									dispatch(setPaymentMethod(paymentMethods?.find((method) => method.Name === updatedPaymentMethod)));
								}
							}}
						/>
					</>
				);
			case PaymentStep.PAYMENT_DATE:
				return (
					<>
						<CTAHeadings subtitle={steps[PaymentStep.PAYMENT_METHOD - 1].subtitle} title={steps[PaymentStep.PAYMENT_METHOD - 1].title} />
						<PaymentMethods
							selected={paymentMethod}
							onRemove={() => {
								dispatch(clearPaymentMethod());
								dispatch(setPaymentAmount(''));
							}}
						/>
						<CTAHeadings subtitle={steps[PaymentStep.PAYMENT_DATE - 1].subtitle} title={steps[PaymentStep.PAYMENT_DATE - 1].title} />
						<SchedulePaymentForm />
					</>
				);
			case PaymentStep.PAYMENT_AMOUNT:
				return (
					<>
						<Hidden mdUp>
							<Typography className={styles.title} variant="h5" align="center">
								{paymentMode === PaymentMode.NOW ? steps[PaymentStep.PAYMENT_AMOUNT - 1].subtitle : 'Pay account in full'}
							</Typography>
						</Hidden>
						<Hidden smDown>
							<CTAHeadings
								subtitle={paymentMode === PaymentMode.NOW ? steps[PaymentStep.PAYMENT_AMOUNT - 1].subtitle : undefined}
								title={paymentMode === PaymentMode.NOW ? steps[PaymentStep.PAYMENT_AMOUNT - 1].title : 'Pay account in full'}
							/>
						</Hidden>
						<PaymentAmountForm
							paymentAmountButtons={generatePaymentButtons(outstandingAmount)}
							outstandingAmount={outstandingAmount ?? 0}
							paymentAmount={paymentAmount.toString()}
							updatePaymentAmount={(updatedPaymentAmount) => {
								dispatch(clearPaymentAmountError());
								dispatch(setPaymentAmount(updatedPaymentAmount));
							}}
							paymentAmountError={paymentAmountError}
							isDisabled={paymentMode !== PaymentMode.NOW}
							merchantFeePercent={paymentMethod?.Name === AvailablePaymentMethod.CARD ? merchantFeePercent : undefined}
							onEnterKeyPress={isLastStep ? () => handleOfferContinue(false) : handleContinue}
						/>
					</>
				);
			case PaymentStep.PAYMENT_OFFER:
				return (
					<>
						<AssistantCTAHollow
							subtitle={steps[PaymentStep.PAYMENT_OFFER - 1].subtitle}
							title={`${displayNameShort}${steps[PaymentStep.PAYMENT_OFFER - 1].title}`}
						/>
						<ComparisonCard
							className={styles.comparisonCard}
							items={[
								{
									amount: {
										value: outstandingAmount ?? 0,
									},
									icon: <Flag />,
									identifier: {
										color: 'secondary',
										content: 'NEW OFFER',
									},
									specifications: [
										{
											content: 'Close your account',
											hasCheckmark: true,
										},
									],
								},
								{
									amount: {
										value: parseFloat(paymentAmount),
									},
									icon: <Destination />,
									identifier: {
										color: 'primary',
										content: 'Original plan',
									},
									specifications: [
										{
											content: 'Requires further payments',
										},
									],
								},
							]}
						/>
					</>
				);
			default:
				// This will never be reached.
				return <div>Not Found</div>;
		}
	};

	const handleBack = () => {
		if (isFirstStep || inArrangement) {
			if (returnRoute) {
				history.replace(returnRoute);
			} else {
				history.push(AccountRoutes.OVERVIEW.path);
			}
		} else if (activeStep === PaymentStep.PAYMENT_DATE) {
			dispatch(clearPaymentMethod());
			dispatch(setPaymentAmount(''));
		} else if (activeStep === PaymentStep.PAYMENT_AMOUNT && ccPayNowOnly) {
			dispatch(clearPaymentMethod());
			dispatch(setPaymentAmount(''));
		} else {
			dispatch(setActiveStep(activeStep - 1));
		}
	};

	const routeToPayment = () => {
		if (paymentMethod?.Name === AvailablePaymentMethod.BPAY) {
			history.push(PaymentRoutes.PAYMENT_AGREEMENT.path);
		} else if (paymentMethod?.Name === AvailablePaymentMethod.DIRECT) {
			history.push(PaymentRoutes.PAYMENT_DD.path);
		} else {
			history.push(PaymentRoutes.PAYMENT_CC.path);
		}
	};

	const handleOfferContinue = (acceptOffer: boolean = false) => {
		if (acceptOffer) {
			dispatch(setPaymentAmount(outstandingAmount?.toString() ?? ''));
			// Hide the offer page if the user accepts the offer
			dispatch(setActiveStep(PaymentStep.PAYMENT_AMOUNT));
		}
		routeToPayment();
	};

	const handleContinue = async () => {
		if (activeStep === PaymentStep.PAYMENT_AMOUNT) {
			try {
				await paymentAmountSchema.validate({ paymentAmount });
				if (calculatePercent(paymentAmount, outstandingAmount ?? 0) >= 90 && parseFloat(paymentAmount) !== outstandingAmount) {
					// Show offer page if amount is greater than 90% and not total
					dispatch(setActiveStep(activeStep + 1));
				} else {
					routeToPayment();
				}
			} catch (err) {
				if (err instanceof Yup.ValidationError) {
					// Set payment amount to total
					if (err.type === 'max') {
						dispatch(setPaymentAmount(outstandingAmount?.toString() ?? ''));
					}
					dispatch(setPaymentAmountError(err.message));
				}
			}
		} else if (activeStep === PaymentStep.PAYMENT_DATE) {
			if (hasFixedAmount) {
				routeToPayment();
			} else {
				if (paymentMethod?.Name === AvailablePaymentMethod.CARD && paymentMode === PaymentMode.NOW) {
					dispatch(setPaymentAmount(''));
				} else {
					dispatch(setPaymentAmount(outstandingAmount?.toString() ?? ''));
				}
				dispatch(setActiveStep(activeStep + 1));
			}
		} else {
			dispatch(setActiveStep(activeStep + 1));
		}
	};

	return (
		<>
			{loading && <DashboardLoader />}
			{!loading && (
				<>
					<Card className={clsx(styles.root, activeStep === PaymentStep.PAYMENT_OFFER && styles.offer)} raised>
						{renderStepContent(activeStep)}
						<Hidden smDown>
							<Container disableGutters={true} className={styles.container}>
								<Grid alignItems="center" container direction="row" justifyContent="center" spacing={2}>
									<Grid item xs={isFirstStep || isLastStep ? 12 : 6} className={clsx(isLastStep && styles.backButton)}>
										<Button color="primary" fullWidth size="large" variant="outlined" onClick={handleBack}>
											Back
										</Button>
									</Grid>
									{isLastStep && (
										<Grid item xs={12} className={clsx(isLastStep && styles.continueButton)}>
											<Button
												color="primary"
												fullWidth
												size="large"
												variant="outlined"
												onClick={isLastStep ? () => handleOfferContinue(false) : handleContinue}
											>
												Continue with original offer
											</Button>
										</Grid>
									)}
									{!isFirstStep && (
										<Grid item xs={isLastStep ? 12 : 6} className={clsx(isLastStep && styles.continueOfferButton)}>
											<Button
												color="secondary"
												fullWidth
												size="large"
												variant="contained"
												onClick={isLastStep ? () => handleOfferContinue(true) : handleContinue}
											>
												{isLastStep ? 'Increase and close account' : 'Continue'}
											</Button>
										</Grid>
									)}
								</Grid>
							</Container>
						</Hidden>
					</Card>
					<Hidden mdUp>
						<ButtonStack>
							{!isFirstStep && (
								<Button
									color="secondary"
									fullWidth
									size="large"
									variant="contained"
									onClick={isLastStep ? () => handleOfferContinue(true) : handleContinue}
								>
									{isLastStep ? 'Increase and close account' : 'Continue'}
								</Button>
							)}
							{isLastStep && (
								<Button
									color="primary"
									fullWidth
									size="large"
									variant="outlined"
									onClick={isLastStep ? () => handleOfferContinue(false) : handleContinue}
								>
									Continue with original offer
								</Button>
							)}
							<Button color="primary" fullWidth size="large" variant="outlined" onClick={handleBack}>
								Back
							</Button>
						</ButtonStack>
					</Hidden>
				</>
			)}
		</>
	);
};

import React from 'react';

export const ArrowLeft = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M12 20l-8-8 8-8 1.41 1.41L7.83 11H20v2H7.83l5.58 5.59z"
			fillRule="evenodd"
		/>
	</svg>
);

import { ARLResponse } from '../../../interceptor/base.api.response';

export interface ITransactionOutput {
	date: string;
	amount: number;
	status: string;
	method: string;
	notes?: string;
}

export interface IGetTransaction extends ARLResponse<{ payments: ITransactionOutput[] }> {}

export enum TransactionAPI {
	TRANSACTION = '/api/transaction',
}

/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../elements/ErrorBoundary/errorBoundary';
import { ArfRoute, BusinessRoutes, paymentRoutePrefix, WebRoutes } from '../enums/routerPath';
import { RootState } from '../store/rootReducer';
import { AuthenticatedRoute } from './guards/authenticated.guard';
import { Page } from './page';
import { AccountLayout } from '../scenes/AccountLayout/accountLayout';
import { resetPaymentForm } from '../store/features/paymentForm/paymentFormSlice';
import { persistRootKey } from '../store/store';
import { FourOhFour } from '../scenes/Customer/scenes/FourOhFour/fourOhFour';
import { UnsupportedBrowser } from '../elements/UnsupportedBrowser/unsupportedBrowser';
import { ErrorModal } from '../elements/ErrorModal/errorModal';
import { LoginRoute } from './guards/login.guard';
import { Login } from '../scenes/Customer/scenes/Login/login';
import { CustomerHome } from '../scenes/Customer/scenes/Home/home';
import { SupportCentreLayout } from '../scenes/Customer/scenes/SupportCentre/supportCentreLayout';
import { Contact } from '../scenes/Customer/scenes/Contact/contact';
import { HowItWorks } from '../scenes/Customer/scenes/HowItWorks/howItWorks';
import { Privacy } from '../scenes/Customer/scenes/Privacy/privacy';
import { Complaints } from '../scenes/Customer/scenes/Complaints/complaints';
import { Terms } from '../scenes/Customer/scenes/Terms/terms';
import { Hardship } from '../scenes/Customer/scenes/Hardship/hardship';
import { AboutUs } from '../scenes/Customer/scenes/AboutUs/aboutUs';
import { BusinessHome } from '../scenes/Business/scenes/Home/home';
import { Industries } from '../scenes/Business/scenes/Industries/industries';
import { Services } from '../scenes/Business/scenes/Services/services';
import { Compliance } from '../scenes/Business/scenes/Compliance/compliance';
import { BusinessContact } from '../scenes/Business/scenes/Contact/contact';
import { CustomerARFHome } from '../scenes/ARF/scenes/Home/home';
import { HardshipARF } from '../scenes/ARF/scenes/Hardship/hardship';
import { PrivacyPolicyARF } from '../scenes/ARF/scenes/Privacy/privacyPolicy';

export const inPaymentFlow = (path: string) => path.split('/').includes(paymentRoutePrefix);

export default function Routes() {
	const authenticated = useSelector((state: RootState) => state.auth.user?.authenticated ?? false);

	const location = useLocation();
	const dispatch = useDispatch();
	const [prevLocation, setPrevLocation] = React.useState<null | string>(null);

	React.useEffect(() => {
		if (location.pathname !== prevLocation) {
			// Reset on new tab if not payment page
			if (prevLocation === null && !inPaymentFlow(location.pathname) && sessionStorage.getItem(`persist:${persistRootKey}`)) {
				dispatch(resetPaymentForm());
			}
			// Reset when navigating from payment flow to non payment
			if (prevLocation && inPaymentFlow(prevLocation) && !inPaymentFlow(location.pathname)) {
				dispatch(resetPaymentForm());
			}
			setPrevLocation(location.pathname);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<ErrorBoundary>
			<UnsupportedBrowser />
			<ErrorModal />
			<Switch>
				<Page
					exact
					path={WebRoutes.HOME.path}
					title={WebRoutes.HOME.title}
					description={WebRoutes.HOME.description}
					component={CustomerHome}
				/>
				<LoginRoute
					exact
					isAuthenticated={authenticated}
					path={WebRoutes.CUSTOMERS.path}
					title={WebRoutes.CUSTOMERS.title}
					description={WebRoutes.CUSTOMERS.description}
					component={Login}
					redirectPath={WebRoutes.ACCOUNT.path}
				/>
				<AuthenticatedRoute path={WebRoutes.ACCOUNT.path} component={AccountLayout} isAuthenticated={authenticated} />
				<Page exact path={WebRoutes.HOW_IT_WORKS.path} title={WebRoutes.HOW_IT_WORKS.title} component={HowItWorks} />
				<Page exact path={WebRoutes.ABOUT.path} title={WebRoutes.ABOUT.title} component={AboutUs} />
				<Page exact path={WebRoutes.CONTACT.path} title={WebRoutes.CONTACT.title} component={Contact} />
				<Page exact path={WebRoutes.PRIVACY.path} title={WebRoutes.PRIVACY.title} component={Privacy} />
				<Page exact path={WebRoutes.COMPLAINTS.path} title={WebRoutes.COMPLAINTS.title} component={Complaints} />
				<Page exact path={WebRoutes.HARDSHIP.path} title={WebRoutes.HARDSHIP.title} component={Hardship} />
				<Page exact path={WebRoutes.TERMS.path} title={WebRoutes.TERMS.title} component={Terms} />
				<Page exact path={BusinessRoutes.HOME.path} title={BusinessRoutes.HOME.title} component={BusinessHome} />
				<Page exact path={BusinessRoutes.INDUSTRIES.path} title={BusinessRoutes.INDUSTRIES.title} component={Industries} />
				<Page exact path={BusinessRoutes.OUR_SERVICES.path} title={BusinessRoutes.OUR_SERVICES.title} component={Services} />
				<Page exact path={BusinessRoutes.COMPLIANCE.path} title={BusinessRoutes.COMPLIANCE.title} component={Compliance} />
				<Page exact path={BusinessRoutes.CONTACT.path} title={BusinessRoutes.CONTACT.title} component={BusinessContact} />
				<Page exact path={WebRoutes.FOUR_OH_FOUR.path} title={WebRoutes.FOUR_OH_FOUR.title} component={FourOhFour} />
				<Page exact path={ArfRoute.HOME.path} title={ArfRoute.HOME.title} component={CustomerARFHome} />
				<Page exact path={ArfRoute.HARDSHIP.path} title={ArfRoute.HARDSHIP.title} component={HardshipARF} />
				<Page exact path={ArfRoute.PRIVACY.path} title={ArfRoute.PRIVACY.title} component={PrivacyPolicyARF} />
				<Route path={WebRoutes.SUPPORT.path} component={SupportCentreLayout} />
				<Redirect to={WebRoutes.FOUR_OH_FOUR.path} />
			</Switch>
		</ErrorBoundary>
	);
}

/* eslint-disable import/no-cycle */
import { AnyAction, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isSameDay, parseISO } from 'date-fns';
import {
	AvailablePaymentMethod,
	NegotiationModal,
	NegotiationOffer,
	PaymentFrequency,
	PaymentMode,
	PaymentStep,
	PaymentType,
} from '../../../enums/paymentForm';
import { PaymentRoutes } from '../../../enums/routerPath';
import arlApi from '../../../interceptor/api';
import { calcSavings } from '../../../services/helpers/calculation.helpers';
import { nextBusinessDate } from '../../../services/helpers/date.helpers';
import { calculatePercent } from '../../../services/helpers/paymentForm.helpers';
import { history } from '../../history';
import { RootState } from '../../rootReducer';
import { AppThunk } from '../../store';
import { fetchBpayDetails } from '../customer/bpay/customerBpaySlice';
import { fetchCustomerDetails } from '../customer/details/customerSlice';
import { oneOffFrequency } from '../paymentFrequency/paymentFrequencySlice';
import { IGetPaymentFrequency } from '../paymentFrequency/types';
import { IGetPaymentMethods } from '../paymentMethod/types';
import {
	BPAY,
	CreditCardArrangement,
	DirectDebit,
	ICard,
	ICreateArrangement,
	ICreateArrangementResponse,
	IDirectDebit,
	IGetArrangementCheck1,
	IGetArrangementCheck2,
	IGetArrangementCheckLow,
	IPaymentSuccess,
	IPayNow,
	IQuickPayInput,
	MinAmounts,
	PaymentAPI,
	PayNow,
	IPaymentFailure,
} from './types';

const today = new Date().toISOString();
const nextBusinessDay = nextBusinessDate(new Date()).toISOString();

const creditCardPayment: IGetPaymentMethods = {
	Name: AvailablePaymentMethod.CARD,
	PaymentMethodID: 2,
	AbbreviatedName: 'CC',
};

const initialMinAmounts: MinAmounts = {
	'One Off PIF': undefined,
	Weekly: undefined,
	Fortnightly: undefined,
	Monthly: undefined,
};

// Have to use paymentAmount as string due to keypad input
export interface PaymentFormState {
	inArrangement: boolean;
	activeStep: PaymentStep;
	paymentMethod: IGetPaymentMethods | undefined;
	paymentDate: string;
	paymentMode: PaymentMode;
	paymentType: PaymentType | undefined;
	holidayAlertOpen: boolean;
	paymentAmount: string;
	paymentFrequency: IGetPaymentFrequency | undefined;
	checkedLow: boolean;
	minAmounts: MinAmounts;
	paymentAmountAttempt: number;
	negotiationModalOpen: NegotiationModal | undefined;
	negotiationOfferScreen: NegotiationOffer | undefined;
	loading: boolean;
	error: string | undefined;
	paymentAmountError: string | undefined;
	paymentSuccess: IPaymentSuccess | undefined;
	paymentFailure: IPaymentFailure | undefined;
	paymentCard: ICard | undefined;
	paymentDirectDebit: IDirectDebit | undefined;
	hasFixedAmount: boolean;
	returnRoute: string | undefined;
}

const initialState: PaymentFormState = {
	inArrangement: false,
	activeStep: PaymentStep.PAYMENT_METHOD,
	paymentMethod: undefined,
	paymentDate: nextBusinessDay,
	paymentMode: PaymentMode.NOW,
	paymentType: undefined,
	holidayAlertOpen: false,
	paymentAmount: '',
	paymentFrequency: undefined,
	checkedLow: false,
	minAmounts: initialMinAmounts,
	paymentAmountAttempt: 0,
	negotiationModalOpen: undefined,
	negotiationOfferScreen: undefined,
	loading: false,
	error: undefined,
	paymentAmountError: undefined,
	paymentSuccess: undefined,
	paymentFailure: undefined,
	paymentCard: undefined,
	paymentDirectDebit: undefined,
	hasFixedAmount: false,
	returnRoute: undefined,
};

export const paymentFormSlice = createSlice({
	name: 'paymentForm',
	initialState,
	reducers: {
		setInArrangement(state, action: PayloadAction<boolean>) {
			state.inArrangement = action.payload;
			// Can only pay now when in arrangement
			if (action.payload) {
				state.paymentMethod = creditCardPayment;
				state.activeStep = PaymentStep.PAYMENT_AMOUNT;
				state.paymentDate = today;
				state.paymentMode = PaymentMode.NOW;
			}
		},
		payNowFlow(state) {
			state.paymentMethod = creditCardPayment;
			state.paymentType = PaymentType.SINGLE;
			state.paymentDate = today;
			state.paymentMode = PaymentMode.NOW;
			state.activeStep = PaymentStep.PAYMENT_AMOUNT;
		},
		payNowWithAmount(state, action: PayloadAction<string>) {
			state.paymentMethod = creditCardPayment;
			state.paymentType = PaymentType.SINGLE;
			state.paymentDate = today;
			state.paymentMode = PaymentMode.NOW;
			state.activeStep = PaymentStep.PAYMENT_AMOUNT;
			state.paymentAmount = action.payload;
			state.hasFixedAmount = true;
			history.push(PaymentRoutes.PAYMENT_CC.path);
		},
		setHasFixedAmount(state, action: PayloadAction<boolean>) {
			state.hasFixedAmount = action.payload;
		},
		setActiveStep(state, action: PayloadAction<number>) {
			state.activeStep = action.payload;
		},
		// eslint-disable-next-line consistent-return
		setPaymentType(state, action: PayloadAction<PaymentType>) {
			// Check if a new wizard has started
			if (state.paymentType && state.paymentType !== action.payload) {
				return { ...initialState, paymentType: action.payload };
			}
			state.paymentType = action.payload;
			if (state.paymentType === PaymentType.SINGLE) {
				state.paymentFrequency = oneOffFrequency;
			}
		},
		clearPaymentType(state) {
			state.paymentType = undefined;
		},
		setPaymentMethod(state, action: PayloadAction<IGetPaymentMethods | undefined>) {
			state.paymentMethod = action.payload;
			// If payment method is CC & today set payment method to now
			if (
				state.paymentMethod?.Name === AvailablePaymentMethod.CARD &&
				state.paymentType === PaymentType.SINGLE &&
				isSameDay(parseISO(state.paymentDate), parseISO(today))
			) {
				state.paymentMode = PaymentMode.NOW;
			} else {
				state.paymentMode = PaymentMode.SCHEDULED;
			}
			state.activeStep = PaymentStep.PAYMENT_DATE;
		},
		clearPaymentMethod(state) {
			state.paymentMethod = undefined;
			state.activeStep = PaymentStep.PAYMENT_METHOD;
		},
		setPaymentDate(state, action: PayloadAction<string>) {
			state.paymentDate = action.payload;
			// If payment method is CC & today set payment method to now
			if (
				state.paymentMethod?.Name === AvailablePaymentMethod.CARD &&
				state.paymentType === PaymentType.SINGLE &&
				isSameDay(parseISO(action.payload), parseISO(today))
			) {
				state.paymentMode = PaymentMode.NOW;
			} else {
				state.paymentMode = PaymentMode.SCHEDULED;
			}
		},
		clearPaymentDate(state) {
			state.paymentDate = today;
			state.paymentMode = PaymentMode.NOW;
			state.holidayAlertOpen = false;
		},
		setHolidayAlertOpen(state, action: PayloadAction<boolean>) {
			state.holidayAlertOpen = action.payload;
		},
		setPaymentAmount(state, action: PayloadAction<string>) {
			state.paymentAmount = isNaN(parseFloat(action.payload)) ? '' : action.payload;
		},
		setPaymentAmountError(state, action: PayloadAction<string>) {
			state.paymentAmountError = action.payload;
		},
		clearPaymentAmountError(state) {
			state.paymentAmountError = undefined;
		},
		setPaymentFrequency(state, action: PayloadAction<IGetPaymentFrequency | undefined>) {
			state.paymentFrequency = action.payload;
		},
		increasePaymentAmountAttempt(state) {
			state.paymentAmountAttempt += 1;
		},
		setNegotiationModalOpen(state, action: PayloadAction<NegotiationModal | undefined>) {
			state.negotiationModalOpen = action.payload;
		},
		setNegotiationOffer(state, action: PayloadAction<NegotiationOffer | undefined>) {
			state.negotiationOfferScreen = action.payload;
		},
		getMinPayment(state) {
			state.loading = true;
			state.error = undefined;
		},
		getMinPaymentSuccess(state, action: PayloadAction<MinAmounts>) {
			state.loading = false;
			state.minAmounts = action.payload;
			state.error = undefined;
		},
		getMinPaymentFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		setCheckedLow(state, action: PayloadAction<boolean>) {
			state.checkedLow = action.payload;
		},
		resetPaymentForm() {
			return initialState;
		},
		resetPaymentFormSuccess(state) {
			// Reset store and keep properties needed for success screens
			return { ...initialState, paymentSuccess: state.paymentSuccess };
		},
		resetPaymentFormFailure(state) {
			// Reset store and keep properties needed for failure screens
			return { ...initialState, paymentFailure: state.paymentFailure };
		},
		resetPaymentFormQuickPay(state) {
			// Reset store and keep properties needed for success screens
			return { ...initialState, paymentAmount: state.paymentAmount };
		},
		payNow(state) {
			state.loading = true;
			state.paymentSuccess = undefined;
		},
		payNowSuccess(state, action: PayloadAction<IPayNow>) {
			const paymentSuccess: IPaymentSuccess = {
				payNow: action.payload.data.transaction,
				paymentMode: state.paymentMode,
				paymentMethod: state.paymentMethod,
				paymentDate: state.paymentDate,
				paymentAmount: action.payload.data.totalAmount.toString(),
				paymentType: state.paymentType,
				paymentCard: state.paymentCard,
				inArrangement: state.inArrangement,
			};
			state.paymentSuccess = paymentSuccess;
			state.loading = false;
		},
		payNowFailure(state, action: PayloadAction<IPaymentFailure>) {
			state.loading = false;
			state.paymentSuccess = undefined;
			state.paymentFailure = action.payload;
		},
		setPaymentCard(state, action: PayloadAction<ICard>) {
			state.paymentCard = action.payload;
		},
		setPaymentDirectDebit(state, action: PayloadAction<IDirectDebit>) {
			state.paymentDirectDebit = action.payload;
		},
		arrangementSetup(state) {
			state.loading = true;
			state.paymentSuccess = undefined;
		},
		arrangementSetupSuccess(state, action: PayloadAction<ICreateArrangementResponse>) {
			const paymentSuccess: IPaymentSuccess = {
				arrangement: action.payload,
				paymentMode: state.paymentMode,
				paymentMethod: state.paymentMethod,
				paymentDate: state.paymentDate,
				paymentAmount: state.paymentAmount,
				paymentType: state.paymentType,
				paymentCard: state.paymentCard,
			};
			state.paymentSuccess = paymentSuccess;
			state.loading = false;
		},
		arrangementSetupFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.paymentSuccess = undefined;
			state.paymentFailure = { paymentAmount: state.paymentAmount, errors: [action.payload] };
		},
		setReturnRoute(state, action: PayloadAction<string | undefined>) {
			state.returnRoute = action.payload;
		},
	},
});

export const {
	setInArrangement,
	payNowFlow,
	payNowWithAmount,
	setHasFixedAmount,
	setActiveStep,
	setPaymentType,
	clearPaymentType,
	setPaymentMethod,
	clearPaymentMethod,
	setPaymentDate,
	clearPaymentDate,
	setHolidayAlertOpen,
	setPaymentAmount,
	setPaymentAmountError,
	clearPaymentAmountError,
	setPaymentFrequency,
	increasePaymentAmountAttempt,
	setNegotiationModalOpen,
	setNegotiationOffer,
	getMinPayment,
	getMinPaymentSuccess,
	getMinPaymentFailure,
	setCheckedLow,
	resetPaymentForm,
	resetPaymentFormSuccess,
	resetPaymentFormFailure,
	resetPaymentFormQuickPay,
	payNow,
	payNowSuccess,
	payNowFailure,
	setPaymentCard,
	setPaymentDirectDebit,
	arrangementSetup,
	arrangementSetupSuccess,
	arrangementSetupFailure,
	setReturnRoute,
} = paymentFormSlice.actions;

export default paymentFormSlice.reducer;

export const fetchArrangementCheck =
	(paymentAmount: string, paymentFrequency: IGetPaymentFrequency | undefined): AppThunk =>
	async (dispatch, getState) => {
		try {
			if (!paymentFrequency) {
				return;
			}
			const { paymentAmountAttempt, checkedLow } = getState().paymentForm;
			let { minAmounts } = getState().paymentForm;
			if (paymentAmountAttempt === 0) {
				const { data: checkFirstData } = await arlApi.get<IGetArrangementCheck1>(PaymentAPI.CHECK_FIRST, {
					params: {
						amount: parseFloat(paymentAmount),
						frequency: paymentFrequency.Name,
					},
				});
				minAmounts = {
					'One Off PIF': undefined,
					Weekly: checkFirstData.data.amounts.SuggestedWeekly,
					Fortnightly: checkFirstData.data.amounts.SuggestedFortnightly,
					Monthly: checkFirstData.data.amounts.SuggestedMonthly,
				};
				dispatch(increasePaymentAmountAttempt());
				dispatch(getMinPaymentSuccess(minAmounts));
				paymentArrangementNegotiationLogic(paymentAmount, paymentFrequency, checkFirstData.success, dispatch, getState);
				return;
			}
			if (paymentAmountAttempt === 1) {
				const { data: checkSecondData } = await arlApi.get<IGetArrangementCheck2>(PaymentAPI.CHECK_SECOND, {
					params: {
						amount: parseFloat(paymentAmount),
						frequency: paymentFrequency.Name,
					},
				});
				if (checkSecondData.success) {
					dispatch(increasePaymentAmountAttempt());
					dispatch(getMinPaymentSuccess(minAmounts));
					paymentArrangementNegotiationLogic(paymentAmount, paymentFrequency, checkSecondData.success, dispatch, getState);
					return;
				}
			}
			if (!checkedLow) {
				const { data: checkLowData } = await arlApi.get<IGetArrangementCheckLow>(PaymentAPI.CHECK_LOW);
				minAmounts = {
					'One Off PIF': undefined,
					Weekly: checkLowData.data.amounts.MinimumWeekly,
					Fortnightly: checkLowData.data.amounts.MinimumFortnightly,
					Monthly: checkLowData.data.amounts.MinimumMonthly,
				};
				if (checkLowData.success) {
					dispatch(setCheckedLow(true));
				} else {
					dispatch(getMinPaymentFailure(checkLowData.message));
				}
			}
			dispatch(increasePaymentAmountAttempt());
			dispatch(getMinPaymentSuccess(minAmounts));
			const minPay = minAmounts[paymentFrequency.Name as PaymentFrequency] ?? 0;
			paymentArrangementNegotiationLogic(paymentAmount, paymentFrequency, parseFloat(paymentAmount) >= minPay, dispatch, getState);
		} catch (err) {
			if (err.isAxiosError) {
				const e: AxiosError = err;
				dispatch(getMinPaymentFailure(e.response?.data.message));
			} else {
				dispatch(getMinPaymentFailure('An unknown error occurred.'));
			}
		}
	};

const paymentArrangementNegotiationLogic = (
	paymentAmount: string,
	paymentFrequency: IGetPaymentFrequency,
	isAccepted: boolean,
	dispatch: ThunkDispatch<RootState, void, AnyAction>,
	getState: () => RootState,
) => {
	const minPay = getState().paymentForm.minAmounts[paymentFrequency.Name as PaymentFrequency] ?? 0;
	const paymentAmountAttempt = getState().paymentForm.paymentAmountAttempt ?? 0;
	const percent = calculatePercent(paymentAmount, minPay);
	const outstanding = getState().customer.customerDetails?.outstandingAmt ?? 0;
	if (isAccepted) {
		if (percent > 150 && percent <= 200) {
			const offer = calcSavings(outstanding, parseFloat(paymentAmount), paymentFrequency.PeriodicFrequencyID, 0, undefined, true);
			if ((offer?.monthsSavings ?? 0) > 0) {
				dispatch(setNegotiationOffer(NegotiationOffer.SAVINGS));
				dispatch(setActiveStep(PaymentStep.PAYMENT_OFFER));
			} else {
				dispatch(setNegotiationModalOpen(NegotiationModal.WE_ACCEPT));
			}
		} else {
			dispatch(setNegotiationModalOpen(NegotiationModal.WE_ACCEPT));
		}
	} else {
		dispatch(setNegotiationOffer(paymentAmountAttempt === 1 ? NegotiationOffer.ALMOST : NegotiationOffer.LOW));
		dispatch(setActiveStep(PaymentStep.PAYMENT_OFFER));
	}
};

export const submitPayNow =
	(payNowInput: PayNow): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(payNow());
			const { data } = await arlApi.post<IPayNow>(PaymentAPI.PAY_NOW, payNowInput);
			if (data.success && data.data.transaction) {
				dispatch(payNowSuccess(data));
				dispatch(fetchCustomerDetails());
				history.push(PaymentRoutes.PAYMENT_SUCCESS.path);
			} else {
				const paymentFailure: IPaymentFailure = {
					paymentAmount: data.data.totalAmount.toString(),
					errors: data.data.transaction?.errors ?? [data.message],
				};
				dispatch(payNowFailure(paymentFailure));
				history.push(PaymentRoutes.PAYMENT_FAILED.path);
			}
		} catch (err) {
			const e: AxiosError = err;
			const paymentFailure: IPaymentFailure = {
				paymentAmount: err.isAxiosError ? e.response?.data.data.totalAmount : payNowInput.amount,
				errors: err.isAxiosError ? [e.response?.data.message] : ['An unknown error occured'],
			};
			dispatch(payNowFailure(paymentFailure));
			history.push(PaymentRoutes.PAYMENT_FAILED.path);
		}
	};

export const submitQuickPay =
	(cardInput: IQuickPayInput): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(payNow());
			const { data } = await arlApi.post<IPayNow>(PaymentAPI.QUICK_PAY, cardInput);
			if (data.success && data.data.transaction) {
				dispatch(payNowSuccess(data));
			} else {
				const paymentFailure: IPaymentFailure = {
					paymentAmount: data.data.totalAmount.toString(),
					errors: data.data.transaction?.errors ?? [data.message],
				};
				dispatch(payNowFailure(paymentFailure));
			}
		} catch (err) {
			const e: AxiosError = err;
			const paymentFailure: IPaymentFailure = {
				paymentAmount: err.isAxiosError ? e.response?.data.data.totalAmount : cardInput.amount,
				errors: err.isAxiosError ? [e.response?.data.message] : ['An unknown error occured'],
			};
			dispatch(payNowFailure(paymentFailure));
			history.push(PaymentRoutes.PAYMENT_FAILED.path);
		}
	};

export const scheduleCardPayment =
	(creditcardArrangement: CreditCardArrangement): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(arrangementSetup());
			const { data } = await arlApi.post<ICreateArrangement>(PaymentAPI.CREDITCARD, creditcardArrangement);
			if (data.success) {
				dispatch(arrangementSetupSuccess(data.data.arrangement));
				dispatch(fetchCustomerDetails());
				history.push(PaymentRoutes.ARRANGEMENT_SUCCESS.path);
			} else {
				dispatch(arrangementSetupFailure(data.message));
				history.push(PaymentRoutes.ARRANGEMENT_FAILED.path);
			}
		} catch (err) {
			const e: AxiosError = err;
			dispatch(arrangementSetupFailure(err.isAxiosError ? e.response?.data.message : 'An unknown error occured'));
			history.push(PaymentRoutes.ARRANGEMENT_FAILED.path);
		}
	};

export const scheduleBPayPayment =
	(bpay: BPAY): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(arrangementSetup());
			const { data } = await arlApi.post<ICreateArrangement>(PaymentAPI.BPAY, bpay);
			if (data.success) {
				dispatch(arrangementSetupSuccess(data.data.arrangement));
				dispatch(fetchBpayDetails());
				dispatch(fetchCustomerDetails());
				history.push(PaymentRoutes.ARRANGEMENT_SUCCESS.path);
			} else {
				dispatch(arrangementSetupFailure(data.message));
				history.push(PaymentRoutes.ARRANGEMENT_FAILED.path);
			}
		} catch (err) {
			const e: AxiosError = err;
			dispatch(arrangementSetupFailure(err.isAxiosError ? e.response?.data.message : 'An unknown error occured'));
		}
	};

export const scheduleDirectDebitPayment =
	(directDebit: DirectDebit): AppThunk =>
	async (dispatch) => {
		try {
			dispatch(arrangementSetup());
			const { data } = await arlApi.post<ICreateArrangement>(PaymentAPI.DIRECTDEBIT, directDebit);
			if (data.success) {
				dispatch(arrangementSetupSuccess(data.data.arrangement));
				dispatch(fetchCustomerDetails());
				history.push(PaymentRoutes.ARRANGEMENT_SUCCESS.path);
			} else {
				dispatch(arrangementSetupFailure(data.message));
				history.push(PaymentRoutes.ARRANGEMENT_FAILED.path);
			}
		} catch (err) {
			const e: AxiosError = err;
			dispatch(arrangementSetupFailure(err.isAxiosError ? e.response?.data.message : 'An unknown error occured'));
		}
	};

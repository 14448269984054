import { Box, Container, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PantheraBreadcrumbs } from '../../../../elements/PantheraBreadcrumbs/pantheraBreadcrumbs';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { ArfRoute } from '../../../../enums/routerPath';
import { HeroTitle } from '../../../Customer/elements/HeroTitle/heroTitle';
import { ARFCustomerFooter } from '../../elements/Footer/footer';
import { ARFCustomerHeader } from '../../elements/Header/header';
import useStyles from './privacy';

const breadcrumbs = [
	{
		title: 'ARF',
		LinkProps: {
			to: ArfRoute.HOME.path,
		},
	},
	{
		title: 'Privacy policy',
		LinkProps: {
			to: ArfRoute.PRIVACY.path,
		},
	},
];

export const PrivacyPolicyARF: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Box className={styles.root}>
			<ARFCustomerHeader className={styles.greyHeader} />
			<HeroTitle title="Privacy Policy" subtitle="Last updated 12/10/2022" />
			<Container className={styles.row} maxWidth="lg">
				<Spacing units={3}>
					<PantheraBreadcrumbs isCollapsed={!screenAtDesktopWidth} items={breadcrumbs} />
					<Typography variant="h4">Our Commitment to Privacy</Typography>
					<Typography variant="body1">
						We are committed to protecting your privacy. This Privacy Policy outlines how we (Australian Receivables Finance) (ARF) manage
						your personal information. We comply with the Privacy Act 1988 (Cth) and the Australian Privacy Principles. For information on
						how we manage credit information and credit eligibility information please refer to our Credit Reporting Policy.
					</Typography>
					<Typography variant="h4">What kind of personal information do we collect and hold?</Typography>
					<Typography variant="body1">The kinds of personal information that we collect and hold include:</Typography>
					<ul>
						<li>your identity and contact information such as name, date of birth, address, phone number and email address;</li>
						<li>
							information about your accounts and products with us such as the balance, the type of product, creditor/s, repayment
							schedules and history, and communication history;
						</li>
						<li>
							your financial information such as your occupation or business activities, employment status, income, expenses, repayment
							capacity, assets, liabilities and other personal information related to this;
						</li>
						<li>your credit information and credit eligibility information;</li>
						<li>your payment information such as BSB and account number, credit card number or alternative payment method details;</li>
						<li>information about your activities on our websites, portals, social media, live chat or other digital platforms;</li>
						<li>information or documentation which relates to a complaint that you raise with us about your account or product; and</li>
						<li>for employment purposes, details about your experience, skills, qualifications, background and other related matters.</li>
					</ul>
					<Typography variant="body1">
						Inbound and outbound phone calls to and from our call centres are recorded and may be monitored or reviewed for compliance,
						record-keeping and training purposes. We periodically delete call-recordings.
					</Typography>
					<Typography variant="body1">
						As our interactions with you are most likely to relate to your account or product, it is not possible for you to interact with
						us anonymously or using a pseudonym unless the interaction is a general inquiry or does not relate to your account.
					</Typography>
					<Typography variant="body1">
						There may be occasions where you voluntarily disclose sensitive information in your communications with us. Examples of
						sensitive information include:
					</Typography>
					<ul>
						<li>information about your race or ethnic origin;</li>
						<li>information about your political opinions or associations;</li>
						<li>information about your religion or philosophical beliefs;</li>
						<li>information about trade union membership or associations;</li>
						<li>information about your sexual information or practices;</li>
						<li>information about your criminal history; and</li>
						<li>your health or genetic information</li>
					</ul>
					<Typography variant="body1">
						We will only collect sensitive information about you if you consent to us collecting this information. We will also only
						collect sensitive information if it is directly relevant to our functions or activities. For example, we will only collect
						this information if it impacts your ability to make repayments.
					</Typography>

					<Typography variant="h4">How do we collect and hold personal information?</Typography>
					<Typography variant="body1">We may collect and hold personal information in ways that include:</Typography>
					<ul>
						<li>
							from you, during our interactions with you or when you access or use our online services such as our website, portal or
							social media and through the use of cookies;
						</li>
						<li>
							from your creditors and financial institutions and related service providers when we purchase an account from them, act on
							their behalf as their agent or service provider, are engaged by them to manage or recover an account or provide a service
							relating to this, and are assisting you with a financial solution such as assisting with a loan application or other
							credit product or solution;
						</li>
						<li>from credit reporting bodies or other information providers;</li>
						<li>from third parties that you have authorised to act on your behalf in your interactions with us;</li>
						<li>
							from other third parties such as work colleagues, friends and neighbours only if we are having difficulty locating or
							contacting you;
						</li>
						<li>from your employer about your employment and income;</li>
						<li>from third party information service providers; and</li>
						<li>from applications such as TICA;</li>
						<li>We store any information that we collect electronically in our secure internal customer management system.</li>
					</ul>

					<Typography variant="h4">For what purposes do we collect, hold, use and disclose personal information?</Typography>
					<Typography variant="body1">
						We collect, hold, use and disclose your personal information for the purpose of efficiently and effectively carrying out our
						functions and activities including:
					</Typography>
					<ul>
						<li>assisting you with financial solutions such as credit products and services;</li>
						<li>determining your ability to make repayments;</li>
						<li>locating or contacting you;</li>
						<li>
							buying and recovering accounts, debts and credit facilities from creditors such as banks, finance companies, and utility
							and telecommunication providers;
						</li>
						<li>
							acting as an agent for our clients such as banks, insurers, and utility providers to recover defaulted, cancelled and/or
							terminated credit facilities;
						</li>
						<li>providing accounts receivable management services for our clients;</li>
						<li>recruiting and employing staff;</li>
						<li>monitoring, developing, improving and marketing our products and services; and</li>
						<li>other related functions and activities.</li>
					</ul>

					<Typography variant="h4">How can you update your personal information that we hold?</Typography>
					<Typography variant="body1">
						It is important to us that the personal information we hold about you is accurate and up to date. During the course of our
						relationship with you we request that you inform us if any of your personal information has changed. If you wish to make any
						changes to your personal information, you may contact us or alternatively log in to our customer portal (if applicable) to
						access and update the relevant information.
					</Typography>

					<Typography variant="h4">How can you access your personal information that we hold?</Typography>
					<Typography variant="body1">
						You can make an access request to us for the personal information we hold about you. We will not charge you a fee for making
						an access request, but we may charge a reasonable fee for supplying access to the information we hold about you. When this
						applies, we will advise you upfront that a fee may apply and the basis for the calculation of the fee.
					</Typography>
					<Typography variant="body1">
						Depending on the type of request that you make we may respond to your request immediately, otherwise we will respond to you as
						soon as we reasonably can, but within 30 days. If we are unable to meet this timeframe, we will advise you. There may be
						situations where we are unable to provide you with access to your information, or provide you with the information in the form
						that you request. If this applies, we will advise you of this and the reasons (if we are able to).
					</Typography>

					<Typography variant="h4">How can you seek correction of your personal information that we hold?</Typography>
					<Typography variant="body1">
						If any of the personal information we hold about you is incorrect, inaccurate, or out of date, you may request that we correct
						the information. We may need to consult with other organisations as part of our investigation. If we refuse to correct
						information, we will advise you of this and provide you with the reasons for not correcting the information (if we are able
						to).
					</Typography>
					<Typography variant="body1">
						Depending on the type of correction request that you make we may respond to your request immediately, otherwise we will
						respond to you as soon as we reasonably can, but within 30 days. If we are unable to meet this timeframe, we will advise you.
					</Typography>
					<Typography variant="body1">Our contact details are:</Typography>
					<Typography variant="body1">
						Phone:{' '}
						<Link color="secondary" href="tel:0735359020">
							(07) 3535 9020
						</Link>
						<br />
						Email:{' '}
						<Link color="secondary" href="mailto:resolutions@australianreceivablesfinance.com.au">
							resolutions@australianreceivablesfinance.com.au
						</Link>
						<br />
					</Typography>

					<Typography variant="h4">Complaints</Typography>
					<Typography variant="body1">
						If you believe that we are in breach of any of our obligations under the Australian Privacy Principles or the Privacy Act, you
						may lodge a complaint with our Internal Dispute Resolution Department via the details listed in the previous section. Once
						your complaint has been submitted, we will endeavour to provide a response within 30 days. If we are unable to provide a
						response within 30 days, or if you are dissatisfied with our response to your complaint, you may lodge a complaint with the
						following external bodies:
					</Typography>
					<Typography variant="body1">
						<b>Australian Financial Complaints Authority</b>
						<br />
						Phone: 1800 931 678 (free call)
						<br />
						Email:{' '}
						<Link color="secondary" href="mailto:info@afca.org.au">
							info@afca.org.au
						</Link>
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.afca.org.au" target="_blank">
							www.afca.org.au
						</Link>
						<br />
						Postal Address: GPO Box 3, Melbourne VIC 3001
					</Typography>
					<Typography variant="body1">
						<b>Office of the Australian Information Commissioner</b>
						<br />
						Phone: 1300 363 992
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.oaic.gov.au" target="_blank">
							www.oaic.gov.au
						</Link>
					</Typography>

					<Typography variant="h4">Do we disclose your personal information?</Typography>
					<Typography variant="body1">We may disclose your personal information to organisations that include:</Typography>
					<ul>
						<li>our related bodies corporate;</li>
						<li>
							our authorised agents, legal advisers, and service providers (such as technology service providers, identity verification
							service providers, bulk mail handling providers, document storage providers, information or data providers and process
							servers);
						</li>
						<li>your current or former credit providers and their authorised service providers and agents;</li>
						<li>your authorised representative;</li>
						<li>courts, tribunals, and external dispute resolution schemes;</li>
						<li>where we are required to do so by law, such as under anti-money laundering legislation;</li>
						<li>
							to investors, agents or advisers, or any entity that has or is considering a financial interest in our business or part of
							our business such as a debt portfolio;
						</li>
						<li>
							to third parties and employers when we are conducting employment or income enquiries or attempting to make contact with
							you; and
						</li>
						<li>
							in relation to employment and recruitment, your referees, education provider, employers and our background verification
							service provider to verify information about your employment application.
						</li>
						<li>
							We may also disclose certain personal information to credit reporting bodies. For example, if you fail to meet your
							repayment obligations, we may be entitled to disclose that information to such a body. When such information is disclosed
							to a credit reporting body, that body may include such information in reports that are subsequently provided to credit
							providers to assist them in assessing your credit worthiness. We may disclose information to the following credit
							reporting bodies:
						</li>
					</ul>
					<Typography variant="body1">
						<b>illion</b>
						<br />
						Phone: 13 23 33
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.illion.com.au/" target="_blank">
							www.illion.com.au
						</Link>
					</Typography>
					<Typography variant="body1">
						<b>Experian</b>
						<br />
						Phone: 1300 783 684
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.experian.com.au/" target="_blank">
							www.experian.com.au
						</Link>
					</Typography>
					<Typography variant="body1">
						<b>Equifax</b>
						<br />
						Phone: 13 83 32
						<br />
						Website:{' '}
						<Link color="secondary" href="http://www.equifax.com.au/" target="_blank">
							www.equifax.com.au
						</Link>
					</Typography>

					<Typography variant="h4">Do we disclose personal information to overseas recipients?</Typography>
					<Typography variant="body1">We do not currently disclose information to overseas recipients.</Typography>

					<Typography variant="h4">Direct marketing</Typography>
					<Typography variant="body1">
						Occasionally we may use your personal information to provide you with current information about offers you may find of
						interest, changes to our organisation, or new products or services being offered by us. If you do not wish to receive
						marketing information, you may at any time contact us to request that you no longer receive direct marketing. We will give
						effect to your request at the earliest possible opportunity.
					</Typography>
				</Spacing>
			</Container>
			<ARFCustomerFooter />
		</Box>
	);
};

export const brand = window.config.REACT_APP_COMPANY;
const businessRoutePrefix = 'business';
const supportRoutePrefix = 'support';
export const accountRoutePrefix = 'account';
export const paymentRoutePrefix = 'payment';
const settingsRoutePrefix = 'settings';
const arfRoutePrefix = 'arf';

export const WebRoutes = {
	HOME: {
		path: '/',
		title: brand,
		description: `${brand} is one of Australia’s premier debt purchasing and debt collection companies. If you have received a notice from us, please log in to manage your account.`,
	},
	LOGIN: {
		path: '/#login',
	},
	CUSTOMERS: {
		path: '/customers',
		title: brand,
		description: `Login to manage your account with your ${brand} reference account number.`,
	},
	ACCOUNT: {
		path: `/${accountRoutePrefix}`,
		title: `Account | ${brand}`,
	},
	HOW_IT_WORKS: {
		path: '/how-it-works',
		title: `How it works | ${brand}`,
		description: `${brand} is one of Australia’s premier debt purchasing and debt collection companies. If you have received a notice from us, please log in to manage your account.`,
	},
	ABOUT: {
		path: '/about',
		title: `About us | ${brand}`,
		description: 'Traditional collections rebuilt for the digital world of today.',
	},
	SUPPORT: {
		path: `/${supportRoutePrefix}`,
		title: `Help | ${brand}`,
		description: 'Learn more about how we operate and how we strive to act ethically and with integrity towards our customers.',
	},
	PRIVACY: {
		path: '/privacy',
		title: `Privacy policy | ${brand}`,
		description: `${brand} understands how important it is to protect your personal information. View our privacy policy for more information.`,
	},
	COMPLAINTS: {
		path: '/complaints',
		title: `Complaints policy | ${brand}`,
		description: `${brand} has an internal process for handling complaints which is free and accessible to all customers.`,
	},
	HARDSHIP: {
		path: '/hardship',
		title: `Hardship | ${brand}`,
		description: `${brand} understands that at times our customers experience financial hardship and we recognise the need to engage these people with empathy and understanding.`,
	},
	TERMS: {
		path: '/terms',
		title: `Terms and conditions | ${brand}`,
		description: 'The terms and conditions for the use of our website are outlined here.',
	},
	CONTACT: {
		path: '/contact',
		title: `Contact Us | ${brand}`,
		description: `Call ${window.config.REACT_APP_CONTACT_NUMBER} or email ${window.config.REACT_APP_CONTACT_EMAIL}`,
	},

	FOUR_OH_FOUR: {
		path: '/not-found',
		title: `Page not found | ${brand}`,
	},
};

export const BusinessRoutes = {
	HOME: {
		path: `/${businessRoutePrefix}`,
		title: `${brand} for Business`,
		description:
			'Over 30 years of collections experience with modern digital technologies to give the best outcomes to our customers and clients.',
	},
	INDUSTRIES: {
		path: `/${businessRoutePrefix}/industries`,
		title: `Industry sectors we service | ${brand} for Business`,
		description:
			'Our extensive experience allows us to serve clients across a wide range of industries with custom tailored solutions and pricing to meet your needs.',
	},
	OUR_SERVICES: {
		path: `/${businessRoutePrefix}/services`,
		title: `Services | ${brand} for Business`,
		description:
			'We can provide services to meet your needs at any time in the collections life cycle as well as customised workflows to meet your specific needs.',
	},
	COMPLIANCE: {
		path: `/${businessRoutePrefix}/compliance`,
		title: `Compliance | ${brand} for Business`,
		description: `When it comes to statutory compliance regimes, including those relating to Privacy and Trade Practices, ${brand} offers industry leading business practices and procedures.`,
	},
	CONTACT: {
		path: `/${businessRoutePrefix}/contact`,
		title: `Contact Us | ${brand} for Business`,
		description: `Call ${window.config.REACT_APP_CONTACT_NUMBER} or email ${window.config.REACT_APP_CONTACT_EMAIL}`,
	},
};

export const SupportRoutes = {
	FAQ: {
		path: `/${supportRoutePrefix}/faq`,
		title: `FAQ - Support centre | ${brand}`,
		description: `List of frequently asked questions that may help you answer any queries you may have about your account with ${brand}.`,
	},
	OTHER_ASSISTANCE: {
		path: `/${supportRoutePrefix}/other-assistance`,
		title: `Other assistance - Support centre | ${brand}`,
		description: 'Useful information and links to help you manage your finances.',
	},
	DEBT_RESOURCES: {
		path: `/${supportRoutePrefix}/debt-resources`,
		title: `Helpful debt resources - Support centre | ${brand}`,
		description: 'Useful information and links to help you manage your finances.',
	},
	OUR_COMMITMENT: {
		path: `/${supportRoutePrefix}/our-commitment`,
		title: `Our commitment to you - Support centre | ${brand}`,
		description: 'Our Commitment to you and your responsibility to us.',
	},
};

export const AccountRoutes = {
	OVERVIEW: {
		path: `/${accountRoutePrefix}/overview`,
		title: `Overview | ${brand}`,
	},
	ACCOUNT_SETTINGS: {
		path: `/${accountRoutePrefix}/settings`,
		title: `Account settings | ${brand}`,
	},
	CONTACT_GENERAL: {
		path: `/${accountRoutePrefix}/contact`,
		title: `Contact us | ${brand}`,
	},
	HARDSHIP: {
		path: `/${accountRoutePrefix}/hardship`,
		title: `Apply for hardship | ${brand}`,
	},
};

export const PaymentRoutes = {
	SINGLE_PAYMENT: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/single`,
		title: `Make a single payment | ${brand}`,
	},
	PAYMENT_PLAN: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/plan`,
		title: `Set up payment plan | ${brand}`,
	},
	PAYMENT_DD: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/directdebit`,
		title: `Direct debit payment | ${brand}`,
	},
	PAYMENT_CC: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/creditcard`,
		title: `Credit card payment | ${brand}`,
	},
	PAYMENT_SUCCESS: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/success`,
		title: `Payment successful | ${brand}`,
	},
	PAYMENT_FAILED: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/failed`,
		title: `Payment unsuccessful | ${brand}`,
	},
	PAYMENT_AGREEMENT: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/agreement`,
		title: `Payment agreement | ${brand}`,
	},
	ARRANGEMENT_SUCCESS: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/agreement/success`,
		title: `All set | ${brand}`,
	},
	ARRANGEMENT_FAILED: {
		path: `/${accountRoutePrefix}/${paymentRoutePrefix}/agreement/failed`,
		title: `Plan set up unsuccessful | ${brand}`,
	},
};

export const SettingsRoutes = {
	SUMMARY: {
		path: `/${accountRoutePrefix}/${settingsRoutePrefix}#summary`,
		title: `Summary | ${brand}`,
	},
	CONTACTS: {
		path: `/${accountRoutePrefix}/${settingsRoutePrefix}#contacts`,
		title: `Contacts | ${brand}`,
	},
	PAYMENTS: {
		path: `/${accountRoutePrefix}/${settingsRoutePrefix}#payments`,
		title: `Payments | ${brand}`,
	},
	SCHEDULES: {
		path: `/${accountRoutePrefix}/${settingsRoutePrefix}#schedules`,
		title: `Schedules | ${brand}`,
	},
};

export const ArfRoute = {
	HOME: {
		path: `/${arfRoutePrefix}`,
		title: 'ARF',
		description:
			'Over 30 years of collections experience with modern digital technologies to give the best outcomes to our customers and clients.',
	},
	HARDSHIP: {
		path: `/${arfRoutePrefix}/hardship`,
		title: 'Hardship | ARF',
	},
	PRIVACY: {
		path: `/${arfRoutePrefix}/privacy`,
		title: 'Privacy | ARF',
	},
};

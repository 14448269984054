import { makeStyles } from '@material-ui/core';
import { getThemeStyle, spacingUnits, subtleBackgroundColor, bodyContentMarketingMaxWidth } from '../../../../theme/theme';
import MuiTheme from '../../../../theme';
import backgroundREDConcave from '../../../../static/images/marketing/background-red-concave.png';
import communications from '../../../../static/images/marketing/communications.jpg';

import helpCentreBanner from '../../../../static/images/marketing/help-centre-banner.jpg';

const borderColor = getThemeStyle('paletteTertiary100');

export const images = {
	backgroundREDConcave,
	communications,
	'help-centre-banner': helpCentreBanner,
};

export default makeStyles(
	(theme) => ({
		concave: {
			backgroundColor: '#A62424',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			margin: [[-spacingUnits(3), 0]],
			minHeight: 680,
			paddingBottom: spacingUnits(14),
			paddingTop: spacingUnits(6),
			position: 'relative',
			zIndex: 1,

			[MuiTheme.breakpoints.down('sm')]: {
				marginTop: -spacingUnits(5),
			},

			[MuiTheme.breakpoints.down('xs')]: {
				marginTop: -spacingUnits(6.5),
			},

			'& h1': {
				color: getThemeStyle('paletteCommonWhite'),
				marginTop: spacingUnits(8),
				maxWidth: 550,
			},

			'& $institutionsGrid': {
				borderRadius: getThemeStyle('shapeBorderRadius') / 1.5,
				marginTop: spacingUnits(6),
				maxWidth: 550,
				overflow: 'hidden',

				[MuiTheme.breakpoints.up('md')]: {
					maxWidth: 1066,
				},

				'& > div > div': {
					display: 'flex',
					alignItems: 'center',

					border: `0.5px solid ${borderColor}`,
					padding: [[spacingUnits(1.5), spacingUnits(2)]],

					'& > svg': {
						width: 48,
					},

					'& > p': {
						fontWeight: 500,
						marginLeft: spacingUnits(1),
					},
				},
			},
		},
		concaveContainer: {
			justifyContent: 'center',
		},
		root: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			width: '100%',
			overflowX: 'hidden',
		},
		row: {
			paddingTop: spacingUnits(8),
			paddingBottom: spacingUnits(8),

			'&:nth-child(even)': {
				backgroundColor: subtleBackgroundColor,
			},

			'& p': {
				fontSize: '2rem',
				lineHeight: 1.8,
			},

			'& h1': {
				lineHeight: 1.1,
				[theme.breakpoints.down('xs')]: {
					fontSize: '4rem',
				},
			},
		},
		imgWrapper: {
			[theme.breakpoints.down('md')]: {
				justifyContent: 'center',
			},
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		svgWrapper: {
			display: 'flex',
			width: '100%',
			height: 'auto',
			maxHeight: 470,
			[theme.breakpoints.down('sm')]: {
				maxHeight: 300,
			},

			'& svg': {
				height: 'auto',
				width: '100%',
			},
		},
		heroTitle: {
			fontSize: '6rem',
		},
		buttonContainer: {
			marginTop: spacingUnits(2),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		button: {
			paddingLeft: spacingUnits(8),
			paddingRight: spacingUnits(8),
		},

		faqLinkAnchor: {
			position: 'absolute',
			marginTop: -spacingUnits(30),
		},
		faq: {
			position: 'relative',
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			padding: [[spacingUnits(3), 0]],

			'& > div': {
				maxWidth: bodyContentMarketingMaxWidth,

				'& > div > .MuiPaper-root': {
					display: 'flex',
					overflow: 'hidden',

					'&$helpCentreBanner': {
						alignItems: 'stretch',

						backgroundColor: getThemeStyle('paletteTertiary700'),

						[MuiTheme.breakpoints.down('sm')]: {
							flexDirection: 'column',
						},

						'& a': {
							width: 300,
						},

						'& > div': {
							'&:first-of-type': {
								padding: spacingUnits(4),
								width: '100%',

								'& *': {
									color: getThemeStyle('paletteCommonWhite'),
								},

								'& ul': {
									listStyleType: 'square',
									marginBottom: 0,
									paddingLeft: spacingUnits(3),

									'& li': {
										fontSize: '1.8rem',
										fontWeight: 500,

										'&:not(:first-of-type)': {
											marginTop: spacingUnits(2.5),
										},

										'& a': {
											color: '#81C6FA',

											'& svg': {
												fill: '#df3535',
												marginBottom: -4,
												marginLeft: -2,
												width: 18,
											},
										},
									},
								},

								'& button': {
									width: 200,
								},
							},

							'&:last-of-type': {
								backgroundImage: `url('${images['help-centre-banner']}')`,
								backgroundPosition: '100% 70%',
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								minHeight: 250,
								width: '100%',

								[MuiTheme.breakpoints.up('md')]: {
									backgroundSize: '200%',
									width: '48%',
								},
							},
						},
					},

					'&$debtHelplineBanner': {
						alignItems: 'center',
						justifyContent: 'center',

						backgroundColor: getThemeStyle('paletteCommonBlack'),
						color: getThemeStyle('paletteCommonWhite'),
						padding: [[spacingUnits(4), spacingUnits(8), spacingUnits(4), spacingUnits(5)]],

						[MuiTheme.breakpoints.down('sm')]: {
							alignItems: 'flex-start',
							flexDirection: 'column',
							padding: [[spacingUnits(4), spacingUnits(5)]],
						},

						'& > a': {
							marginRight: spacingUnits(6),
							maxWidth: 246,
							width: '100%',

							[MuiTheme.breakpoints.down('sm')]: {
								marginBottom: spacingUnits(2),
								marginRight: 0,
								maxWidth: 200,
							},
						},
					},
					width: '100%',
				},
			},
		},

		help: {
			backgroundColor: getThemeStyle('paletteCommonWhite'),
			overflow: 'hidden',
			padding: [[spacingUnits(9), 0]],

			'& .MuiGrid-item > div': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',

				maxWidth: 316,
				textAlign: 'center',

				[MuiTheme.breakpoints.up('sm')]: {
					height: 354,
				},

				'& > img': {
					width: 154,
				},

				'& > h5': {
					marginTop: spacingUnits(3),
				},

				'& > p': {
					fontSize: '1.8rem',
					marginTop: spacingUnits(2),
				},

				'& > button': {
					marginTop: spacingUnits(2),
					maxWidth: 200,
					width: '100%',

					[MuiTheme.breakpoints.up('sm')]: {
						marginTop: 'auto',
					},
				},

				'& > a': {
					marginTop: spacingUnits(2),
					maxWidth: 200,
					width: '100%',

					[MuiTheme.breakpoints.up('sm')]: {
						marginTop: 'auto',
					},

					'& > button': {
						width: '100%',
					},
				},
			},
		},
		institutionsGrid: {},
		colorss: {},
		helpCentreBanner: {},
		debtHelplineBanner: {},
		fileUploadContainer: {},
		fileUploadWell: {},
		uploadedFile: {},
		contactDetailsSection: {},
	}),

	{ name: 'home' },
);

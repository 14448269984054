import React from 'react';
import { Link, ListItem, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Spacing } from '../../../../../../../../elements/Spacing/spacing';
import { WebRoutes } from '../../../../../../../../enums/routerPath';

const company = window.config.REACT_APP_COMPANY;
const address = window.config.REACT_APP_COMPANY_ADDRESS;
const email = window.config.REACT_APP_CONTACT_EMAIL;
const emailHref = `mailto:${email}?Subject=Website%20Contact`;
const number = window.config.REACT_APP_CONTACT_NUMBER;
const numberHref = window.config.REACT_APP_CONTACT_NUMBER_HREF;

export const faqs = [
	{
		title: `Who is ${company}?`,
		content: (
			<Spacing units={1}>
				<Typography variant="body1">
					{company} is a credit recovery solutions provider, offering a range of collections options for organisations and government
					agencies. If you have received communication from us, we are here to help you to find the best solution to repay your debt. You
					have the ability to self-manage your account and payment options by logging into our customer portal.
				</Typography>
			</Spacing>
		),
	},
	{
		title: 'How can I pay my account?',
		content: (
			<Spacing units={2}>
				<Typography variant="body1">
					The best way to make payments on the account is to utilise our{' '}
					<Link component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
						customer portal
					</Link>{' '}
					system. If your log in is not working, you can contact us on <a href={numberHref}>{number}</a> and one of our staff will be happy
					to assist.
				</Typography>
			</Spacing>
		),
	},
	{
		title: 'What can I do if I don’t think this debt is mine?',
		content: (
			<Spacing units={2}>
				<Typography variant="body1">
					Please contact us online or by phone on <a href={numberHref}>{number}</a> to discuss further.
				</Typography>
			</Spacing>
		),
	},
	{
		title: 'Why are you contacting me for another organisation?',
		content: (
			<Spacing units={2}>
				<Typography variant="body1">
					We have been employed to resolve your account on behalf of the organisation you have an outstanding balance with. We are here to
					help you to find the best solution to repay your debt. You have the ability to self-manage your account and payment options by
					logging into our{' '}
					<Link component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
						customer portal
					</Link>
					, or you can contact us on <a href={numberHref}>{number}</a> to discuss further.
				</Typography>
			</Spacing>
		),
	},
	{
		title: 'How can I clear my debt and get my credit file updated?',
		content: (
			<Spacing units={2}>
				<Typography variant="body1">
					By logging into our account through our{' '}
					<Link component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
						customer portal
					</Link>
					, or by contacting one of our friendly team members on <a href={numberHref}>{number}</a>. When your outstanding balance is paid in
					full, your profile will be updated.
				</Typography>
			</Spacing>
		),
	},
	{
		title: 'What happens if I’m unable to pay my debt at this time?',
		content: (
			<Typography component="span" variant="body1">
				We are committed to dealing with you in fair and reasonable manner. We will tailor a solution to fit your individual circumstances.
				Please contact us to begin the discussion by logging into our{' '}
				<Link component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
					customer portal
				</Link>{' '}
				or contact us on the following contact details:
				<ListItem>
					<li>
						Phone (local) - <a href={numberHref}>{number}</a>
					</li>
					<li>
						Email - <a href={emailHref}>{email}</a>
					</li>
					<li>Mailing Address - {address} </li>
				</ListItem>
			</Typography>
		),
	},
	{
		title: 'If I miss a payment, how do I make it up?',
		content: (
			<Typography variant="body1">
				Please utilise our{' '}
				<Link component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
					customer portal
				</Link>{' '}
				or contact us on <a href={numberHref}>{number}</a> to ensure you make up your payment.
			</Typography>
		),
	},
	{
		title: 'What do I do if my contact details change?',
		content: (
			<Typography variant="body1">
				Please contact us to update your details by logging into our{' '}
				<Link component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
					customer portal
				</Link>{' '}
				or by contacting one of our operators on <a href={numberHref}>{number}</a>.
			</Typography>
		),
	},
];

import { AppBar, Button, Container, IconButton, Toolbar, useScrollTrigger } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import clsx from 'clsx';
import LogoImg from '../../../../elements/Branding/arf_logo.png';
import { WrapWithLink } from '../../../../elements/WrapWithLink/wrapWithLink';
import { WebRoutes, ArfRoute } from '../../../../enums/routerPath';
import { spacingUnits } from '../../../../theme/theme';
import useStyles from './header.styles';
import { LinesStacked } from '../../../../elements/PantheraIcon/LinesStacked';
import { CustomerMobileHeader } from '../../../Customer/elements/Header/elements/Mobile/mobileHeader';

type CustomerHeaderProps = {
	className?: string;
};

export const ARFCustomerHeader: React.FC<CustomerHeaderProps> = ({ className }) => {
	const styles = useStyles();
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});
	const [mobileOpen, setMobileOpen] = React.useState(false);

	return (
		<>
			{mobileOpen && <CustomerMobileHeader onClose={() => setMobileOpen(false)} />}
			<AppBar className={clsx(styles.root, className, scrollTrigger ? styles.scrolled : undefined)} position="relative" elevation={0}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<WrapWithLink
							className={styles.logo}
							LinkProps={{
								to: ArfRoute.HOME.path,
							}}
						>
							<img src={LogoImg} width={85} height={85} alt="logo" />
						</WrapWithLink>
						<div className={styles.grow} />
						<div className={styles.sectionDesktop}>
							<Button color="secondary" size="medium" variant="contained" component={RouterLink} to={WebRoutes.CUSTOMERS.path}>
								Manage my account
							</Button>
						</div>
						<div className={styles.sectionMobile}>
							<Button
								color="secondary"
								size="small"
								variant="contained"
								style={{ marginRight: spacingUnits(0.5) }}
								component={RouterLink}
								to={WebRoutes.CUSTOMERS.path}
							>
								Manage my account
							</Button>
							<IconButton edge="end" color="primary" aria-label="menu" onClick={() => setMobileOpen(true)}>
								<LinesStacked style={{ width: 24 }} />
							</IconButton>
						</div>
					</Toolbar>
				</Container>
			</AppBar>
		</>
	);
};

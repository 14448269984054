/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
	useTheme,
	useMediaQuery,
	Card,
	List,
	ListItem,
	Typography,
	Hidden,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './dashboardLoader.styles';

export const DashboardLoader: React.FC = () => {
	const styles = useStyles();
	const theme = useTheme();
	const screenAtDesktopWidth = useMediaQuery(theme.breakpoints.up('md'));
	const items = Array.from(new Array(4));
	return (
		<>
			<div className={styles.root}>
				<Hidden mdUp>
					<Skeleton variant="circle" width={104} height={104} />
					<Typography
						className={styles.title}
						variant="h1"
						style={{ marginBottom: 15 }}
					>
						<Skeleton width={225} />
					</Typography>
					<Typography variant="body1" style={{ marginBottom: 30 }}>
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton />
						<Skeleton width={200} />
					</Typography>
					<Card raised>
						<div className={styles.list}>
							<div className={styles.listItemWrapper}>
								<div className={styles.listItemIcon}>
									<Skeleton variant="rect" />
								</div>
								<Typography
									className={styles.listItemTitle}
									variant={
										screenAtDesktopWidth
											? 'subtitle1'
											: 'subtitle2'
									}
								>
									<Skeleton />
								</Typography>
								<Typography variant="body2">
									<Skeleton />
								</Typography>
							</div>
						</div>
					</Card>
				</Hidden>
				<Hidden smDown>
					<Typography variant="body1">
						<Skeleton
							width={100}
							height={30}
							style={{ marginLeft: 0 }}
						/>
					</Typography>
					<Typography variant="h5" style={{ marginBottom: 16 }}>
						<Skeleton
							width={300}
							height={40}
							style={{ marginLeft: 0 }}
						/>
					</Typography>
					<Card raised>
						<List className={styles.list}>
							{items.map((item, index) => (
								<ListItem
									key={index}
									disableGutters
									divider={index !== items.length - 1}
								>
									<div className={styles.listItemWrapper}>
										<div className={styles.listItemIcon}>
											<Skeleton variant="rect" />
										</div>
										<Typography
											className={styles.listItemTitle}
											variant={
												screenAtDesktopWidth
													? 'subtitle1'
													: 'subtitle2'
											}
										>
											<Skeleton />
										</Typography>
										<Typography variant="body2">
											<Skeleton />
										</Typography>
									</div>
								</ListItem>
							))}
						</List>
					</Card>
				</Hidden>
			</div>
		</>
	);
};

import { Card } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import useStyles from './persistentCard.styles';

type PersistentCardProps = {
	className?: string;
	severity: 'info' | 'success' | 'warning' | 'error';
};

export const PersistentCard: React.FC<PersistentCardProps> = ({
	children,
	severity,
	className,
	...props
}) => {
	const styles = useStyles();
	return (
		<Card
			className={clsx(
				className,
				styles.root,
				severity && styles.rootWithSeverityPadding,
			)}
			raised
			{...props}
		>
			{severity && (
				<div
					className={clsx(
						styles.severityStripe,
						styles[`severity-${severity}`],
					)}
				/>
			)}
			{children}
		</Card>
	);
};

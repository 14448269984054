import React from 'react';

export const PlusLarge = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox='0 0 24 24' {...props}>
		<g fillRule='evenodd' stroke='none' strokeWidth='1'>
			<path d='M21 13h-8v8a1 1 0 01-2 0v-8H3a1 1 0 010-2h8V3.714a1 1 0 012 0V11h8a1 1 0 010 2z' />
		</g>
	</svg>
);

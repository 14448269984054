import { Container, Divider, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LogoImg from '../../../../elements/Branding/arf_logo.png';
import { LogoStackedNegative } from '../../../../elements/Branding/LogoStackedNegative';
import { ArfRoute, WebRoutes } from '../../../../enums/routerPath';
import useStyles from './footer.styles';

export const ARFCustomerFooter: React.FC = () => {
	const styles = useStyles();
	return (
		<Container className={styles.root} maxWidth={false} disableGutters>
			<Container maxWidth="lg">
				<Grid container alignItems="center" justifyContent="space-evenly" spacing={2}>
					<Grid item className={styles.logo}>
						<img src={LogoImg} width={85} height={85} alt="logo" />
					</Grid>
					<Grid item className={styles.logo}>
						<LogoStackedNegative />
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">SMS us:</Typography>
						<Link color="inherit" underline="none" href="sms: 0485828051">
							0485 828 051
						</Link>
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">Call us:</Typography>
						<Link color="inherit" underline="none" href="tel: 1300845267">
							1300 845 267
						</Link>
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">Email:</Typography>
						<Link color="inherit" underline="none" href="mailto:manager@australianreceivablesfinance.com.au">
							manager@australianreceivablesfinance.com.au
						</Link>
					</Grid>
					<Grid className={styles.contact} item>
						<Typography component="span">Address:</Typography>
						<Typography component="span">Level 3/380 Docklands Dr, Docklands VIC 3008</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
					<Grid item>
						<Link className={styles.navLink} color="inherit" component={RouterLink} to={ArfRoute.PRIVACY.path} underline="none">
							Privacy Policy
						</Link>
					</Grid>
					<Grid item>
						<Link className={styles.navLink} color="inherit" component={RouterLink} to={WebRoutes.TERMS.path} underline="none">
							Terms of Service
						</Link>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
					<Grid item>
						<Typography align="center">Australian Credit Licence - 390702</Typography>
						<Typography align="center">
							Australian Receivables Finance is a member of the Australian Financial Complaints Authority -{' '}
							<Link color="inherit" underline="none" href="tel: 1800931678">
								1800 931 678
							</Link>{' '}
							-{' '}
							<Link color="inherit" underline="none" href="https://www.afca.org.au/" target="_blank">
								www.afca.org.au
							</Link>
						</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid container alignItems="center" spacing={3}>
					<Grid item xs={12}>
						<Typography className={styles.copyright} component="p" variant="caption">
							{`© ${new Date().getFullYear()} ${window.config.REACT_APP_COMPANY_FULL}`}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
};

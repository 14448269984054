import { ListItem, Typography } from '@material-ui/core';
import { BankColored } from '../../../../elements/PantheraIcon/BankColored';
import { BillsColored } from '../../../../elements/PantheraIcon/BillsColored';
import { CarColored } from '../../../../elements/PantheraIcon/CarColored';
import { CartColored } from '../../../../elements/PantheraIcon/CartColored';
import { CreditCardsColored } from '../../../../elements/PantheraIcon/CreditCardsColored';
import { HandshakeColored } from '../../../../elements/PantheraIcon/HandshakeColored';
import { LightBulbColored } from '../../../../elements/PantheraIcon/LightBulbColored';
import { PhoneTowerColored } from '../../../../elements/PantheraIcon/PhoneTowerColored';
import { PiggyBankColored } from '../../../../elements/PantheraIcon/PiggyBankColored';
import Affordable from '../../../../static/images/marketing/avatar-affordable.png';
import Flexible from '../../../../static/images/marketing/avatar-flexible.png';
import Manageable from '../../../../static/images/marketing/avatar-manageable.png';

const number = '1300 845 267';
const email = 'manager@australianreceivablesfinance.com.au';

export const faqs = [
	{
		title: 'Who is Australian Receivables Finance (ARF)?',
		content: (
			<Typography variant="body1">
				ARF is the purchase debt arm of ARL Collect. We purchase debt from financial institutions, telcos, utility and government agencies. If
				you have received communication from us, we are here to help you to find the best solution to repay your debt. You have the ability to
				self-manage your account and payment options by logging into our customer portal.
			</Typography>
		),
	},

	{
		title: 'How can I pay my account?',
		content: (
			<Typography variant="body1">
				The best way to make payments on the account is to utilise our customer portal system. If your log in is not working, you can contact
				us on <a href="tel: 1300845267">{number}</a> and one of our staff will be happy to assist.
			</Typography>
		),
	},
	{
		title: "What can you do if I don't think this debt us mine?",
		content: (
			<Typography variant="body1">
				Please contact us online or by phone on <a href="tel: 1300845267">{number}</a> to discuss further.
			</Typography>
		),
	},
	{
		title: 'Why are you contacting me when my debt is from someone else?',
		content: (
			<Typography variant="body1">
				You had an account that went overdue with a company such as a bank, phone or internet provider, power company, or other credit
				provider. After several unsuccessful attempts to collect payments from you, your service or credit provider put your account up for
				sale. This is common practice in the industry and ARF purchased your overdue account. This means your account is no longer owned by
				the company you signed up with and the outstanding amount is now owed to ARF. You have the ability to self-manage your account and
				payment options by logging into our customer portal, or you can contact us on <a href="tel: 1300845267">{number}</a> to discuss
				further.
			</Typography>
		),
	},
	{
		title: 'How can I clear my debt and get my credit file updated?',
		content: (
			<Typography variant="body1">
				By logging into our account through our customer portal, or by contacting one of our friendly team members on{' '}
				<a href="tel: 1300845267">{number}</a>. When your outstanding balance is paid in full, your profile will be updated
			</Typography>
		),
	},
	{
		title: 'What happens if I’m unable to pay my debt at this time?',
		content: (
			<Typography variant="body1">
				We are committed to dealing with you in fair and reasonable manner. We will tailor a solution to fit your individual circumstances.
				Please contact us to begin the discussion by logging into our customer portal or contact us on the following contact details:
				<ListItem style={{ display: 'list-item' }}>
					Phone (local) - <a href="tel: 1300845267">{number}</a>
				</ListItem>
				<ListItem style={{ display: 'list-item' }}>
					Email – <a href="mailto:manager@australianreceivablesfinance.com.au">{email}</a>
				</ListItem>
				<ListItem style={{ display: 'list-item' }}>Address - PO BOX 14390, City Mail Centre, Melbourne, VIC, 8001</ListItem>
			</Typography>
		),
	},
	{
		title: 'If I miss a payment, how do I make it up?',
		content: (
			<Typography variant="body1">
				Please utilise our customer portal or contact us on <a href="tel: 1300845267">{number}</a> to ensure you make up your payment.
			</Typography>
		),
	},
	{
		title: 'What do I do if my contact details change?',
		content: (
			<Typography variant="body1">
				Please contact us to update your details by logging into our customer portal or by contacting one of our operators on{' '}
				<a href="tel: 1300845267">{number}</a>.
			</Typography>
		),
	},
];

export const helpImages = {
	affordable: Affordable,
	flexible: Flexible,
	manageable: Manageable,
};

export const institutions = [
	{
		icon: <BankColored />,
		text: 'Banking',
	},
	{
		icon: <PhoneTowerColored />,
		text: 'Phone and Internet',
	},
	{
		icon: <CarColored />,
		text: 'Automotive finance',
	},
	{
		icon: <LightBulbColored />,
		text: 'Gas and Electricity',
	},
	{
		icon: <BillsColored />,
		text: 'Personal loans',
	},
	{
		icon: <HandshakeColored />,
		text: 'Business finance',
	},
	{
		icon: <CreditCardsColored />,
		text: 'Credit cards',
	},
	{
		icon: <PiggyBankColored />,
		text: 'Overdraft',
	},
	{
		icon: <CartColored />,
		text: 'Buy now pay later',
	},
];

import React from 'react';

export const PiggyBank = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M53.28,38.23h3.79V42H53.28Z" />
		<path d="M66.8,38.23a20.87,20.87,0,0,0-2.15-3.61V23.06H62.76A14.1,14.1,0,0,0,54,26.12a33.44,33.44,0,0,0-5.72-2,11.39,11.39,0,1,0-19.84.9c-6.77,2.4-12,6.77-14.73,12.09a5.69,5.69,0,0,1,3.41-10.25V23.06a9.48,9.48,0,0,0-4.84,17.63,18.38,18.38,0,0,0-.73,5.13c0,5.79,2.69,11.25,7.59,15.47V72.37H30l1.6-4.82a35,35,0,0,0,13.25.68l1.38,4.14H57.07V64a25.45,25.45,0,0,0,7.56-6.83h7.74v-19ZM38.1,11.43A7.59,7.59,0,1,1,30.52,19,7.59,7.59,0,0,1,38.1,11.43Zm30.47,42h-6c-7.68,9.2-8,7.2-9.32,8.4v6.77H49L47.4,63.93a37.29,37.29,0,0,1-7.4.85,33.7,33.7,0,0,1-10.85-1.9l-1.9,5.69H22.93V59.49c-.28-.32-7.58-5-7.58-13.67,0-7.89,6.35-14.94,15.9-17.73a11.37,11.37,0,0,0,14.51-.66,31.73,31.73,0,0,1,8.89,3.23A11.58,11.58,0,0,1,60.86,27v8.85c3.94,5.78,2.38,4.36,3.33,6.14h4.38Z" />
		<path d="M30.52,32H45.69v3.79H30.52Z" />
	</svg>
);

import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { CustomerHeader } from '../../elements/Header/header';
import useStyles from './aboutUs.styles';
import { CustomerFooter } from '../../elements/Footer/footer';
import { Spacing } from '../../../../elements/Spacing/spacing';
import { LinkArrow } from '../../../../elements/LinkArrow/linkArrow';
import prideBanner from '../../../../static/images/public/arl-pride-web-small.jpg';

export const AboutUs: React.FC = () => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<CustomerHeader className={styles.greyHeader} />
			<Box className={styles.headerContainer} display="flex" justifyContent="flex-start" alignContent="center">
				<Container maxWidth="lg">
					<Typography className={styles.heroText} color="secondary" variant="h1" gutterBottom>
						Traditional collections rebuilt for the digital world of today
					</Typography>
					<Typography className={styles.subText}>
						Combining years of experience with cutting edge technology to deliver friendly solutions that help you take back control.
					</Typography>
				</Container>
			</Box>
			<Container className={styles.row} maxWidth="md">
				<Spacing units={3}>
					<Typography className={styles.underline} variant="h1" gutterBottom>
						Our story
					</Typography>
					<Typography>
						Established in Melbourne in 2003, ARL Collect Pty Ltd (ARL) has grown in scale and capability to become one of the most
						trusted debt recovery organisations in Australia.
					</Typography>
					<Typography>
						Our long-standing relationships with some of the biggest organisations in the country provide us with the opportunity to help
						thousands of customers get back on top of their Debt situation every day.
					</Typography>
					<Typography>
						At ARL, we “pride” ourselves on our commitment to a fresh, customer focused approach to Collections and this guides our
						Company values.
					</Typography>
					<img className={styles.img} alt="PRIDE (Professional, Respect, Integrity, Deliver, Excellence)" src={prideBanner} />
					<Typography>
						In January 2018, ARL was acquired by PF Group Holdings Pty Ltd, a privately-owned credit management business, headquartered in
						Brisbane.
					</Typography>
					<Typography>
						<span style={{ fontWeight: 500 }}>
							That means, when you deal with ARL, you’re dealing with a totally Australian owned and based organisation, whether you are
							speaking to our friendly staff in Melbourne, Echuca or Brisbane.
						</span>
					</Typography>
					<Typography className={styles.underline} variant="h1" gutterBottom>
						Our people
					</Typography>
					<Typography>
						ARL is driven by a Leadership Team that includes some of the most skilled professionals across Credit, Collections and
						Customer Service.
					</Typography>
					<Typography>
						We are dedicated to supporting our people to be the best they can be. With the right leadership, support, technology,
						training, and values, our team members are excited by the opportunity to make a difference for our customers, and committed to
						making that difference.
					</Typography>
					<Typography className={styles.underline} variant="h1" gutterBottom>
						Learning and development
					</Typography>
					<Typography>We are passionate about providing opportunities for our people to never stop learning.</Typography>
					<Typography>
						Our people are supported by our in-house Capability Development team throughout the employee lifecycle, providing 6 weeks of
						dedicated training for all new employees, to ongoing learning sessions and development opportunities. We are passionate about
						providing our people with the resources to learn and grow through face-to-face training and our virtual learning management
						centre, which hosts a wide range of courses, programs and resources available anytime, anywhere, for all ARL Collect
						employees.
					</Typography>
					<Typography>
						If you're looking to join a rewarding and supportive financial services business, you can view our current available
						opportunities on our careers page.{'  '}
						<LinkArrow href="https://pantherafinancegroup.com/careers/opportunities/" target="_blank">
							Find out more
						</LinkArrow>
					</Typography>
				</Spacing>
			</Container>
			<Container className={styles.row} maxWidth="md">
				<div />
			</Container>
			<CustomerFooter />
		</Box>
	);
};

import React from 'react';

export const Phone = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g fillRule="evenodd" stroke="none" strokeWidth="1">
			<path d="M5.667 3.01c.293-.043.46.054.69.36.032.043.065.09.107.15l.112.165 2.191 3.352a.85.85 0 01-.235 1.145c-.052.04-.281.227-.354.288-.372.313-.656.64-.843.99-.049 1.201.84 2.714 2.665 4.54 1.85 1.85 3.38 2.738 4.589 2.662.33-.188.64-.465.939-.821.058-.07.236-.29.263-.321.295-.418.802-.52 1.187-.276l3.35 2.191c.385.269.702.72.67.932-.04.266-.425.944-.947 1.472-.729.737-1.642 1.167-2.73 1.167-2.491 0-5.84-1.955-9.087-5.201C4.96 12.532 3 9.192 3 6.685c0-2.012 1.477-3.506 2.667-3.676z" />
		</g>
	</svg>
);

import React from 'react';

export const ArrowsCreditDebit = () => {
	return (
		<svg viewBox="0 0 80 80">
			<defs>
				<linearGradient id="linearGradient-1" x1="18.726%" x2="77.792%" y1="13.136%" y2="92.844%">
					<stop offset="0%" stopColor="#00D6C0" />
					<stop offset="100%" stopColor="#14B3A9" />
				</linearGradient>
				<linearGradient id="linearGradient-2" x1="18.726%" x2="77.792%" y1="13.146%" y2="92.832%">
					<stop offset="0%" stopColor="#00C2AE" />
					<stop offset="100%" stopColor="#00998F" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<path
					d="M35.32 41.703c.925 0 1.684-.759 1.688-1.687l.004-13.969 5.932 5.931a1.687 1.687 0 002.38 0l1.59-1.588a1.69 1.69 0 000-2.381L35.398 16.491A1.706 1.706 0 0034.203 16c-.441 0-.868.165-1.191.487L21.49 28.009a1.69 1.69 0 000 2.381l1.589 1.588a1.688 1.688 0 002.382 0l5.93-5.931-.011 13.969c0 .924.758 1.683 1.686 1.687h2.254z"
					fill="url(#linearGradient-1)"
					opacity="0.9"
				/>
				<path
					d="M58.846 50.235l-1.589-1.589a1.69 1.69 0 00-2.38 0l-5.933 5.932V40.612a1.685 1.685 0 00-1.686-1.686h-2.255c-.924 0-1.678.754-1.686 1.686l.008 13.966-5.932-5.932a1.69 1.69 0 00-2.38 0l-1.59 1.59a1.69 1.69 0 000 2.381l11.521 11.52c.331.33.758.496 1.191.488.44 0 .868-.165 1.19-.488l11.521-11.52a1.69 1.69 0 000-2.382"
					fill="url(#linearGradient-2)"
				/>
			</g>
		</svg>
	);
};

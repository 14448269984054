import React from 'react';

export const Timer = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg
		data-name="Layer 1"
		viewBox="0 0 80 80"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M59.7,22.66l-.6-.58,1.95-2.34,3.21,2.67,3-3.63-10-8.37-3,3.63,3.21,2.67-2,2.43a27.62,27.62,0,0,0-13-4.54V9.78H37.64V14.6A27.86,27.86,0,1,0,59.7,22.66ZM40,65.5A23.14,23.14,0,1,1,63.14,42.36,23.17,23.17,0,0,1,40,65.5Z" />
		<path d="M49.67,26.66l-8.93,11a4.52,4.52,0,0,0-.74-.06,4.71,4.71,0,1,0,4.41,3l8.93-11Z" />
	</svg>
);

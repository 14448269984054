import React from 'react';

export const BubbleSmsFilled = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 80 80" {...props}>
		<g fillRule="evenodd" stroke="none" strokeWidth="1">
			<path d="M63.808 9.556c4.95 0 8.977 4.027 8.977 8.978v29.403c0 4.95-4.027 8.976-8.977 8.976H34.863l-19 13.22V57.001l-1.243-.159c-4.466-.573-7.835-4.4-7.835-8.905V18.534c0-4.95 4.027-8.978 8.977-8.978zm-6.74 12.19c-1.973 0-3.523.564-4.651 1.692-1.126 1.129-1.69 2.666-1.69 4.614 0 1.485.339 2.71 1.016 3.673.678.966 1.643 1.592 2.895 1.88l3.404.783.247.06c.638.167 1.105.388 1.4.663.33.308.497.74.497 1.3 0 .618-.247 1.1-.741 1.448-.494.346-1.175.52-2.044.52a5.217 5.217 0 01-2.542-.665c-.81-.443-1.68-1.157-2.607-2.141l-2.122 3.616.291.31a10.63 10.63 0 002.99 2.178c1.23.598 2.465.897 3.703.897 2.092 0 3.746-.598 4.96-1.794 1.217-1.195 1.825-2.815 1.825-4.86 0-1.562-.387-2.835-1.161-3.818-.774-.983-1.904-1.63-3.392-1.938l-3.205-.694-.235-.054c-.527-.134-.917-.324-1.17-.568-.285-.28-.43-.67-.43-1.172 0-.54.225-.97.675-1.287.449-.318 1.057-.477 1.823-.477.81 0 1.602.192 2.376.578.774.386 1.565.994 2.376 1.822l2.01-3.673-.285-.29a9.364 9.364 0 00-2.731-1.822c-1.171-.521-2.331-.781-3.481-.781zm-34.033 0c-1.975 0-3.525.564-4.653 1.692-1.126 1.129-1.69 2.666-1.69 4.614 0 1.485.339 2.71 1.016 3.673.678.966 1.643 1.592 2.896 1.88l3.403.783.247.06c.638.167 1.105.388 1.4.663.33.308.497.74.497 1.3 0 .618-.247 1.1-.741 1.448-.493.346-1.175.52-2.044.52a5.213 5.213 0 01-2.541-.665c-.811-.443-1.68-1.157-2.608-2.141l-2.122 3.616.291.31a10.63 10.63 0 002.99 2.178c1.23.598 2.465.897 3.703.897 2.092 0 3.746-.598 4.96-1.794 1.217-1.195 1.825-2.815 1.825-4.86 0-1.562-.387-2.835-1.16-3.818-.774-.983-1.905-1.63-3.393-1.938l-3.204-.694-.235-.054c-.528-.134-.918-.324-1.17-.568-.286-.28-.43-.67-.43-1.172 0-.54.225-.97.674-1.287.45-.318 1.057-.477 1.824-.477.81 0 1.602.192 2.376.578.773.386 1.565.994 2.375 1.822l2.01-3.673-.285-.29a9.365 9.365 0 00-2.73-1.822c-1.172-.521-2.332-.781-3.481-.781zm13.37.29h-4.796v20.249H35.1V28.428l5.084 12.41 5.082-12.352v13.799h3.58v-20.25h-4.795l-3.824 9.546-3.823-9.545z" />
		</g>
	</svg>
);

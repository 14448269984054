import React from 'react';

export const TriangleRight = (
	props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
	<svg viewBox="0 0 13 18" {...props}>
		<path
			d="M12.598 9.763l-8.806 7.452A2 2 0 0 1 .5 15.688V2.312A2 2 0 0 1 3.792.785l8.806 7.452a1 1 0 0 1 0 1.526z"
			fillRule="evenodd"
		/>
	</svg>
);
